import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Cookies from 'universal-cookie'

const resources = {
    pt: {
        translation: {
            "Application_Termos": "Termo de Aceitação",
            "Application_TermosAceite": "Você ainda não aceitou nossos termos para abertura de conta em nossa plataforma.",
            "Application_UserSemVerificacao": "Para efetuar saques, sua conta precisa estar devidamente verificada.",
            "Application_UserSemVerificacaoMSg": "Para verificar, clique no botão abaixo.",
            "Application_IrParaPedidos": "Abrir Pedido",
            "User Disabled": "Cadastro bloqueado ou em auditoria. Entre em contato para mais informações.",
            "Application_UserSuspense": "Cadastro suspenso ou em auditoria. Sua conta está limitada, aguarde o processo de liberação por nossa equipe",
            "Application_Descricao": "Descrição",
            "Application_RelatorioSaqueRobo": "Relatório de saque do Robô",
            "Application_PlataformaMT": "FTM FX PRO",
            "Application_PlataformaMTTxt": "Operações na FTM FX PRO, refere-se a um menu que oferece diversas opções para executar e gerenciar operações em tempo real, como transações, monitoramento ou atividades logísticas.            ",
            "Application_Contato": "Contato",
            "Application_RegistroFCA": "Registro FCA",
            "Application_NaoMostrarSaldoZero": "Ocultar saldo zero",
            "Application_TotalExchange": "Ativos em Exchange",
            "Application_SaldoAtivos": "Ativos",
            "Application_Todos": "Todos",
            "Appication_DepositoTED": "Depósito / TED",
            "Application_MsgErroPagina": "Ops! Houve um erro ao localizar essa página, por favor volte.",
            "Application_Voltar": "Voltar",
            "Application_EntrarNaPlataforma": "Entrar na plataforma agora",
            "Application_FalarComSeugerente": "Falar com Gerente",
            "Application_Configurations": "Configurações",
            "Application_Referal": "Programa de indicação",
            "Application_TextoReferal": "Convide amigos e receba até 100 USDT e 5% de comissão",
            "Application_Inbox": "Caixa de entrada",
            "Application_TextoInbox": "Verifique suas mensagens enviadas por nossa equipe",
            "Application_TextoNotifications": "Veja todas suas notificações.",
            "Application_MaisOpcoes": "+ Opções",
            "Application_TextoAbrirChamado": "Abra um pedido de suporte para nosso time e tenha uma resposta rápida.",
            "Application_TextoSuporte": "Veja todos seus pedidos de ajuda.",
            "Application_TextoSaqueFiat": "Efetue um saque rápido dos seus fundos para sua conta bancária usando PIX, TED ou DOC",
            "Application_TextoEmprestimo": "Faça uma solicitação de empréstimo usando sua margem liberada e pague de forma tranquila e segura.",
            "Application_TextoSaqueCrypto": "Efetue um saque rápido dos seus fundos para Wallet de Crypto",
            "Application_DepositCrypto": "Depositar Crypto (USDT, BTC) e muito mais",
            "Application_TextoDepositar": "Efetue um deposito Fiat de maneira simples e segura",
            "Application_IPOS": "Mercados IPOS",
            "Application_TextoMercadosIpos": "Negocie de forma mais inteligente com nossas várias estratégias automatizadas - fáceis, rápidas e confiáveis",
            "Application_TextoDepositarCrypto": "Efetue um deposito Crypto de maneira simples e segura",
            "Application_TextoMercados": "Potencialize suas negociações com Estratégias Automatizadas: Simples, Rápidas e Confiáveis. Descubra o poder de IPOS e otimize seus resultados.",
            "Application_TextStocks": "Visão geral do mercado de ações com ajuda do nosso IA",
            "Application_TextCrypto": "Visão geral do mercado de crypto e seus Ativos",
            "Application_SelectLanguage": "Selecione o seu idioma",
            "Application_Cadastro": "Criar conta",
            "Application_InvoiceNumber": "Fatura N",
            "Application_InvoiceDate": "Data da fatura",
            "Application_Nivel": "Nível de suporte",
            "Application_Notifications": "Notificações",
            "Applications_TradersMercado": "Traders de mercado",
            "Application_Qtd": "Qtd",
            "Application_Total": "Total",
            "Application_TodosTickes": "Todos os chamados",
            "Application_Deposito": "Depositar",
            "Application_Saque": "Saque",
            "Application_ValorContrato": "Valor do contrato",
            "Application_AguardandoEmprestimo": "Em análise",
            "Application_Aprovado": "Aprovado",
            "Application_Liberado": "Liberado",
            "Application_Cancelado": "Cancelado",
            "Application_NaoAprovado": "Não Aprovado",
            "Application_MsgEmprestimo": "Sua margem para solicitação de empréstimo é de até {{margemEmprestimo}} USD e você poderá efetuar o pagamento em no máximo {{qtdParcel}}x de {{ValorParcel}}",
            "Application_Emprestimos": "Emprestimos",
            "Application_SolicitarEmprestimo": "Solicitar Empréstimo",
            "Application_MargemEmprestimo": "Margem para empréstimo",
            "Application_Score": "Score",
            'Application_DadosInexistentes': "Dados Inexistentes ou não existe contratos para você",
            "Application_ContratoN": "Contrato Nº",
            "Application_QtdParcelas": "Qtd Parcelas",
            "Application_Opcoes": "Opções",
            "Application_informacao": "Informação",
            "Application_Contrato": "CONTRATO N",
            "Application_DetalhesPagamentos": "Detalhes de pagamento de parcelas do contrato N",
            "Application_Shares": "SHARES",
            "Application_VolumeStock": "VOLUME",
            "Application_RSIStock": "RSI",
            "Application_Posicao": "POSIÇÃO",
            "Application_MediasMoveis": "MÉDIAS MÓVEIS",
            "Application_CodBank": "CÓD. BANK",
            "Application_PassCod": "Pass COD",
            "Application_CheckOperar": "Operar com dinâmica de mercado",
            "Application_Comprada": "COMPRADA",
            "Application_Vendedora": "VENDEDORA",
            "Application_CFDS": "CFDS",
            "Application_IniciarOperacao": "INICIAR OPERAÇÃO",
            "Application_ValorTotal": "Valor da compra",
            "Application_ConfirmarOperarStock": "OPERAR COM DINÂMICA DE MERCADO",
            "Application_Capital": "SALDO",
            "Application_SaldoStock": "SALDO",
            "Application_SetupStocks": "Configure seu Software",
            "Application_DataDeNascimento": "Data de nascimento",
            "Application_StartMarketStocks": "Configurar Software",
            "No_Response_Found": "Sem dados no momento, aguardando agentes",
            "Send_Reply_Support": "Enviar resposta",
            "Send_Reply": "Enviando resposta",
            "Application_Responder": "Responder ao ticket",
            "Assunto_Required_Error": "Campo Assunto necessário",
            "Departamento_Required_Error": "Campo departamente necessário",
            "Prioridade_Required_Error": "Campo prioridade necessário",
            "Conteudo_Required_Error": "Campo conteúdo necessário",
            "Application_Anexar_Arquivo": "Anexar arquivo",
            "File_Required_Error": "Campo anexo necessário",
            "Select_Prioridade": "Selecione a prioridade",
            "Select_Departamento": "Selecione o departamento",
            "Application_Supporte": "Suporte",
            "Sending_Request": "Enviando solicitação",
            "Application_msgStopRobo2": "Você poderá efetuar a parada do seu robô de forma segura e sem perdas de capital",
            "Application_DigiteValor": "Digite o valor que deseja converter para saldo",
            "Application_msgSaque": "Confirma a retirada do valor {{valor}} em seu capital disponível?",
            "Application_msgSaqueConfirmado": "O valor de {{valor}}, já está liberado para seu saque no sistema. Obrigado!",
            "Application_MsgSaqueNaoHabilitado": "Robô em operação, contacte o seu analista, pois o lucro pode está sendo utilizado para margem de operação.",
            "Application_BtnSacarDisponivel": "Liberar para saque",
            "Application_SelecioneCarteira": "Selecione a carteira",
            "Application_ContaAwaiterificada": "Documentos enviados, aguardando verificação da equipe",
            "Application_msgDepositarCrip": "Para efetuar um depósito via Criptomoeda, informe a baixo o tipo de moeda que deseja.",
            "Application_Deposit": "Depósito ",
            "Application_euquerodepositar": "Eu quero depositar ",
            "Application_viaRede": "via rede ",
            "Application_SelecionaUmaRede": "Seleciona uma rede",
            "Application_Cryptos": "Cryptos",
            "Application_ValorDeposito": "Digite o valor do depósito",
            "Application_ValorMinimo": "Valor minimo de depósito ",
            "Application_Confirmacoes": "Confirmações na rede ",
            "Application_ConfirmarDeposito": "Confirmar Depósito",
            "Application_Stock": "Stocks",
            "Application_Indices": "Index",
            "Application_Comodites": "Commodities",
            "Application_Arbitragem": "Arbitragem",
            "Application_redeChain": "Selecionar rede",
            "Application_confirm": "Confirmar",
            "Application_cancel": "Cancelar",
            "Application_stop": "Stop",
            "Application_Atencion": "Atenção",
            "Application_msgStopRobo": "Você realmente deseja parar esse Robô? A parada do mesmo poderá ocorrer perdas do mercado e das suas ações.",
            "Application_textMissao": "A Infinity Capital, fundada em 2015 por empreendedores visionários, tem como missão liderar a transformação dos mercados financeiros mundiais. Começando na mineração de ouro, combinamos tradição e inovação para alcançar sucesso e reconhecimento. Com agilidade, abraçamos as criptomoedas e a tecnologia blockchain, expandindo nossa influência. Através da inteligência artificial, revolucionamos nossos processos e negociações, mantendo-nos ágeis diante das mudanças. Nossa plataforma de ponta atrai clientes diversos e nossa sede em Londres simboliza nossa presença global. Somos movidos pela busca incessante da excelência, unindo tradição e inovação para definir o futuro financeiro global.",
            "Application_textVisao": "Nossa visão é liderar a transformação do cenário financeiro global. Combinando tradição e inovação, almejamos redefinir padrões em mineração, criptomoedas e IA. Queremos ser um hub de colaboração, proporcionando decisões ágeis por meio da IA e estabelecendo uma presença global impactante. Nossa cultura de excelência e inovação é impulsionada pela paixão por desafiar limites e criar um futuro financeiro ilimitado.",
            "Application_QuemSomos_01": "Em 1º de novembro de 1999, um grupo de visionários fundou a Infinity Capital, uma empresa inovadora que iniciou suas operações com foco na mineração de ouro e participação em pregões no mercado financeiro. Com uma abordagem única e tecnologicamente avançada, a empresa destacou-se rapidamente nesses setores, utilizando métodos modernos e eficientes para extrair o precioso metal e ganhar credibilidade, proporcionando resultados aos seus investidores.",
            "Application_QuemSomos_02": "Em 22 de novembro de 2005, a empresa foi licenciada pela FCA, órgão regulamentador do Reino Unido, expandindo seus horizontes para abranger capitais institucionais de grandes provedores de liquidez, possibilitando também a colaboração com bancos por meio de ações, commodities e índices.            ",
            "Application_QuemSomos_03": "No entanto, à medida que o mundo testemunhava a ascensão das criptomoedas e da tecnologia blockchain, a Infinity Capital percebeu uma oportunidade de expandir e diversificar seus negócios. Em um movimento estratégico em consonância com essa tendência, a empresa foi incorporada pelo grupo JP Morgan, uma das instituições financeiras mais influentes e respeitadas globalmente.",
            "Application_QuemSomos_04": "Assumindo o papel de diretor da Infinity Capital, juntamente com David Brown, estava Denis Costa, um líder experiente com uma visão voltada para a inovação e tecnologia. Sob sua orientação e com a vasta experiência do JP Morgan, a empresa deu um salto significativo em sua evolução.",
            "Application_QuemSomos_05": "A partir desse momento, a Infinity Capital direcionou seus recursos para o desenvolvimento de robôs impulsionados por inteligência artificial, visando o futuro. Esses robôs foram programados para analisar dados de mercado, identificar tendências e executar negociações de criptomoedas de forma automatizada e precisa. Essa abordagem pioneira logo se tornou uma das marcas registradas da Infinity Capital, estabelecendo-a como líder no campo da negociação algorítmica de criptomoedas.À medida que os robôs da Infinity Capital continuavam a entregar resultados excepcionais, a empresa solidificou sua posição como uma das maiores exchanges do mercado internacional. Sua plataforma de negociação se tornou conhecida pela velocidade, segurança e eficiência, atraindo traders e investidores de todo o mundo.",
            "Application_QuemSomos_06": "Conforme os robôs da Infinity Capital continuavam a entregar resultados excepcionais diante da volatilidade do mercado, a empresa consolidou sua posição como uma das maiores exchanges do mercado internacional. Sua plataforma de negociação tornou-se conhecida pela velocidade, segurança e eficiência, atraindo traders e investidores de todo o mundo.",
            "Application_QuemSomos_07": "Para sustentar seu crescimento e se estabelecer como um nome icônico no setor, a Infinity Capital escolheu a impressionante Heron Tower, localizada no endereço 110 Bishopsgate, London EC2N 4AY, Reino Unido, como sua filial. Essa localização estratégica não apenas reflete a posição de destaque da empresa, mas também simboliza sua visão de elevar o padrão para os serviços financeiros baseados em criptomoedas.",
            "Application_QuemSomos_08": "Ao longo do tempo, a Infinity Capital continuou a inovar e expandir seus horizontes, colaborando com especialistas em IA e blockchain, mantendo-se na vanguarda da tecnologia financeira. Sua história é marcada por desafios superados, conquistas notáveis e um compromisso inabalável com a excelência. Assim, a Infinity Capital continua a moldar o futuro das finanças, explorando novas fronteiras e pavimentando o caminho para uma era de negociação e investimento verdadeiramente revolucionária.",
            "Application_QuemSomos_09": "",
            "Application_QuemSomos_10": "",
            "Application_textValores1": "Inovação Sustentável: Criamos soluções duradouras através de mudanças e tecnologia.",
            "Application_textValores2": "Integridade Inabalável: Mantemos padrões éticos altos para confiança e transparência.",
            "Application_textValores3": "Colaboração Empoderadora: Valorizamos ideias diversas e crescemos juntos.",
            "Application_textValores4": "Adaptação Ágil: Resposta rápida e eficaz às mudanças do mercado.",
            "Application_textValores5": "Excelência Contínua: Busca incessante pela melhoria em todos os aspectos.",
            "Application_textValores6": "Responsabilidade Social e Ambiental: Contribuímos positivamente para comunidades e meio ambiente.",
            "Application_textValores7": "Paixão pelo Crescimento: A paixão por aprender nos mantém inovadores e em crescimento.",
            "Application_Text1": "um time esperando por você. ",
            "Application_Text2": "Uma marca globalmente conhecida",
            "Application_ConteComEquipe": "Conte com nossa equipe para gerir seus ativos e investimentos",
            "Application_RegistrarAgora": "Cadastra-se",
            "Application_Jatenhoconta": "Já tenho uma conta",
            "Application_QuemSomos": "Quem Somos",
            "Join": "Junte-se",
            "Application_TxtQuemSomos": "Uma plataforma focada em facilitar o acesso ao mercado de Criptomoedas e Ações pelo mundo.",
            "Application_Txt2QuemSomos": "Visando principalmente a inclusão e sempre melhorar o acesso de todos ao criptomercado. A Infinity Global tem soluções que vão além das ferramentas tradicionais, nós estamos preparados para a era da inclusão financeira, e do básico ao avançado nós estamos prontos para abrir as portas desse mercado para você.",
            "Application_Servicos": "Serviços",
            "Application_Market": "Preços dos Mercados",
            "Application_agentes": "Agentes",
            "txt_agentes": "Agentes espalhados pelo mundo para um atendimento platinum para você.",
            "Application_plataforma": "Nossa Plataforma",
            "Application_Txtplataforma": "Uma plataforma robusta e simples para você desfrutar no que há de melhor e mais seguro.",
            "Application_txtApi": "A API foi projetada para fornecer uma maneira fácil e eficiente de integrar seu aplicativo de negociação em nossa plataforma.",
            "Description_Bottom": "Infinity Capital é a marca registrada de sites e plataformas digitais operadas pela INFINITY CAPITAL MANAGEMENT LTD. e suas empresas separadas, mas afiliadas, Infinity Capital Securities LLC, Infinity Capital, Infinity Capital Digital Assets LLC, Infinity Capital Cash LLC. 43-45 Dorset Street, London W1U 7NAW 1 U 7 NAREINO UNIDO",
            "Application_Mercados": "Mercados",
            "Application_SetupMercados": "Configure seu robô",
            "Button_Success_Confirure_Robot": "Iniciar processo",
            "Message_Select_Option": "Selecione uma opção",
            "Unavailable_Balance": "Saldo indisponível",
            "Message_Invalide_Token": "Token inváido",
            "Application_Pais": "País: ",
            "Application_SejaBemVindo": "Seja bem-vindo",
            "Application_ContaSemVerificacao": "Sua conta ainda não foi verificada, seu acesso está limitado ao nível básico, para normalizar seu acesso selecione a guia gerenciar e atualize seus dados para concluir a verificação de sua conta.",
            "Application_CadastradoEm": "Cadastrado em: ",
            "Application_exchangePro": "Exchange",
            "Application_Modelo": "Opções",
            "Application_Perfil": "Configuração",
            "Application_Agressivo": "Agressivo",
            "Application_Moderador": "Moderado",
            "Application_Convservador": "Conservador",
            "Application_Selecionar": "Selecionar",
            "Application_OrdemPendente": "Operações Imediatas",
            "Application_ExecucaoInstantanea": "Analisar & Operar",
            "Application_Saldo": "Capital",
            "Application_TipoStop": "Tipo de parada",
            "Application_Valor": "Valor",
            "Application_Lucros": "Lucro Total",
            "Application_Disponivel": "Lucro restante",
            "Application_Porcentagem": "Porcentagem %",
            "Application_StopWin": "Profit",
            "Application_StopLoss": "Stop Loss",
            "Application_BBS": "EMA",
            "Application_RSI": "BOLLINGER ",
            "Application_MACD": "FRACTAL",
            "Application_Volume": "MARGEM OP.",
            "Application_DigiteToken": "Digite o Token enviado pelo seu gerente.",
            "Withdraw Fiat History": "Histórico de retirada Fiat",
            "Submit": "Enviar",
            "Application_EnviarDocumentos": "Upload de documentos",
            "Application_ContaVerificada": "Conta verificada",
            "Apllication_verificacaoConta": "Verificação de conta",
            "Application_NaoAssinado": "Não Assinado",
            "Application_Assinado": "Assinado",
            "Application_Assinado_Sucesso": "FOI ASSINADO COM SUCESSO!",
            "Application_Tickets": "Seus Tickets",
            "Application_Status": "Status",
            "Application_Acoes": "Ações",
            "Application_Aberto": "Aberto",
            "Application_emAndamento": "Em andamento",
            "Application_AguardandoStaff": "Aguardando Staff",
            "Application_AguardandoMembro": "Aguardando Membro",
            "Application_Fechado": "Fechado",
            "Application_Abrir": "Abrir",
            "Application_Fechar": "Fechar",
            "Application_USD": "USD",
            "Application_CopiarPix": "Copiar Cod Pix",
            "Application_Administracao": "Administração",
            "Application_Compilance": "Compilance",
            "Application_Brokers": "Brokers & Agentes",
            "Application_Media": "Média",
            "Application_Alta": "Alta",
            "Application_Informacoes": "Informações da Solicitação",
            "Application_TicketNumber": "Ticket N",
            "Data_Ticket": "Dados do TICKET",
            "Application_Enviado": "Enviado em",
            "Application_Cliente": "Cliente",
            "Application_VoceEstaSendo": "Você está sendo indicado por: ",
            "Application_Naoexiste": "Esse usuário não existe em nosso banco de dador, corrija os dados",
            "Application_WalletUSD": "Sua carteira USD",
            "Application_OperacaoStop": "Seu Robô está em operação, contate seu analista",
            "Application_AtivoInvestments": "Investimento Ativo",
            "Application_Ordens": "Ordens",
            "Application_Investimento": "Investimento: ",
            "Application_Available": "Lucro restante",
            "Application_Retorno": "Lucro Total: ",
            "Application_Robo": "BOTS INFINITY",
            "Application_Hash": "Hash",
            "Application_ContractID": "Contrato Ref",
            "Application_Visualizar": "Visualizar",
            "Application_RelatorioGanhos": "Relatório de ganhos",
            "Application_Ativos": "Ativo",
            "Application_Aguardando": "Aguardando",
            "Application_Finalizado": "Finalizado",
            "Application_ContratoAtual": "Contrato Hash",
            "Application_HashTransaction": "Hash Transação",
            "Application_Ganhos": "Ganhos",
            "Application_priceBuy": "Price Buy",
            "Application_priceSell": "Price Sell",
            "Application_Tipo": "Tipo",
            "Application_Gain": "Gain",
            "Application_Loss": "Loss",
            "Application_Banner_Legenda1": "Legenda do banenr 01",
            "What_Shipping": "Quais métodos de envio estão disponíveis?",
            "How_Long": "Quanto tempo levará para receber meu pacote?",
            "How_Do_I_Track": "Como acompanho meu pedido?",
            "Sign up": "Cadastre-se",
            "Create_Account": "Criar uma conta",
            "Join_Platform": "Junte-se a nossa plataforma",
            "Create your account and start investing": "Crie sua conta e comece a investir!",
            "Home": "Inicio",
            "Assets": "Ativos",
            "Guide": "Guia",
            "Language": "Idioma",
            "News": "Novidades",
            "Trade with ": "Trade com ",
            "Buys and sell criptocurrencies": "Compre e venda criptomoedas",
            "Fast and safe": "Rápido e seguro",
            "Be part of the revolution": "Faça parte da revolução",
            "English": "Inglês",
            "Portuguese": "Português",
            "Spanish": "Espanhol",
            "Your wallet": "Sua carteira",
            "Deposit_Request": "Pedido do depósito",
            "Index Price": "Índice de preço",
            "24h Change": "Variação 24h",
            "1h Change": "Variação 1h",
            "Market Cap": "Cap. Mercado",
            "24h Volume": "Volume 24h",
            "Balance": "Saldo",
            "Size": 'Quantidade',
            "Final Value": "Valor Final",
            "Average price": "Preço Médio",
            "Time": "Tempo",
            "Description": "Descrição",
            "Order History": "Histórico de Ordem",
            "Symbol": "Símbolo",
            "Price": "Preço",
            "Last Modify": "Ultima mudança",
            "Market": "Mercado",
            "Balance in wallet": "Saldo em carteira",
            "Buy Now": "Compre agora",
            "Sell Now": "Venda agora",
            "Pairs": "Pares",
            "Last Price": "Último valor",
            "Change": "Variação",
            "Trade History": "Histórico de Trade",
            "Order not placed": "Ordem não efetuada",
            "Order placed": "Ordem efetuada",
            "You sold": "Você vendeu",
            "You bought": "Você comprou",
            "Order value must be greater than 0": "O valor da ordem deve ser maior que 0",
            "Insufficient funds": "Saldo insuficiente",
            "Hello": "Olá",
            "Trades made": "Trades efetuados",
            "Balance available": "Saldo disponível",
            "Deposit": "Deposito",
            "Account": "Perfil",
            "Logout": "Desconectar",
            "Pay to receive your funds": "Realize o pagamento para receber seus fundos",
            "Value": "Valor",
            "Type": "Tipo",
            "Code": "Código",
            "Pending": "Pendente",
            "Paid": "Pago",
            "Expired": "Expirado",
            "Canceled": "Cancelado",
            "Reversed": "Retornado",
            "Deposit not placed": "Depósito não efetuado",
            "Deposit value must be greater than 0": "O valor do depósito dever maior que 0",
            "Confirmation URL": "URL de Confirmação",
            "Deposit fiat": "Depositar fiat",
            "Deposit crypto": "Depositar cripto",
            "Bankslip": "Boleto",
            "Credit Card": "Cartão de Cred",
            "Access the bankslip": "Acesse o boleto",
            "Access QR code on PicPay": "Acesso o código QR no PicPay",
            "Select your bank": "Selecione seu banco",
            "Your cryptocurrency wallet": "Sua carteira de criptomoedas",
            "Show": "Mostrar",
            "Verified on blockchain": "Veriricada na blockchain",
            "Hide": "Esconder",
            "Use this wallet to receive": "Use esta carteira para receber",
            "Your Ethereum wallet": "Sua carteira Ethereum",
            "This value was added to your founds": "Este valor foi adicionado aos seus fundos",
            "Withdraw crypto": "Sacar Cripto",
            "Withdraw fiat": "Sacar REAL",
            "Address": "Endereço",
            "Copy the address of your external wallet": "Copie o endereço da sua carteira externa",
            "Withdraw": "Saque",
            "Select your token": "Selecione seu token",
            "An error ocurred on your deposit": "Um erro ocorreu no seu depósito",
            "Withdraw value must be greater than 0 and enter a valid address": "O valor do saque deve ser maior que 0 e o endereço válido",
            "Withdraw History": "Histórico de saques",
            "Receiver address": "Endereço destinatário",
            "Eth withdraws are not working at moment": "Saque em Eth não está funcionando no momento",
            "index join": "Junte-se ao ",
            "index subtitle": "Compra, venda e invista em criptomoedas, tokens e criptoativos, na primeira Exchange do Mundo, que possui carteiras e moedas com responsabilidade e propósito social. Além de ser a única Exchange detentora de um ecossistema próprio, que potencializa os investimentos de todos os seus associados.",
            "index steps": "Toda grande jornada começa com os primeiros 3 passos, não é? Venha conosco!",
            "index createAc": "Crie sua conta aqui facilidade é o nosso objetivo",
            "Enjoy": "Aproveite",
            "Deposit your founds": "Deposite seus fundos",
            "Start to buy and sell": "Comece a comprar e a vender",
            "index thirdTitle": "Personalize seu guarda-chuva para proteger seus investimentos",
            "index thirdIndexSubtitle": "A ID UMBRELLA SOCIAL ID possui uma variedade de recursos, e ferramentas tecnológicas, que se aplicam através da organização do conhecimento, difundindo tudo através do compartilhamento para gerar a tão esperada sabedoria coletiva. Através deste conceito criado, podemos ajudar na introdução do iniciante, e até mesmo colaborar para aprimorar ainda mais os experts.",
            "Manage your portfolio": "Manage your portfolio",
            "manage text": "Invista, compre e venda ativos digitais e rastreie-os em um só lugar",
            "Recurring buys": "Recurring buys",
            "recurring text": " Invista na economia disruptiva de forma lenta e segura. Acompanhando as novidades e sugestões através deste portal",
            "Vault protection": "Vault protection",
            "vault text": "Para maior segurança, armazene seus investimentos em seu portfólio pessoal. E aproveite a agilidade de ter seus recursos disponíveis 24 horas por dia, em qualquer lugar do mundo.",
            "Mobile": "Celular",
            "mobile text": "Fique de olho nos mercados através dos aplicativos ID SOCIAL UMBRELLA EXCHANGE. Sistema do  grupo ID IDENTIFICATION",
            "Deposit History": "Histórico de deposito",
            "Password": "Senha",
            "Sign in": "Entrar",
            "Forgot Password?": "Esqueceu a senha?",
            "Remember me": "Lembrar-me",
            "Dont have an account?": "Não possui uma conta?",
            "liquidity text": "Além de acessar os recursos dos seus investimentos em qualquer lugar do mundo, você já está cooperando para um mundo melhor. E nesse mundo os recursos são para serem usados por pessoas. E no modelo que estamos desmontando, as pessoas são escravizadas pelo dinheiro. O que tem mais valor para você ? Dinheiro ou ser humano?",
            "index trust": "Confiando em nossos parceiros, investidores e em nossos valores",
            "trust text": "Nascemos com mais de 50.000 membros investidores, distribuídos em mais de 160 países, sendo a primeira Exchenge do mundo a ter como exigência para cada integrante, ser apoiador do pacto dos tratados dos direitos humanos indicados pela ONU. Pois na visão do nosso fundador, Henrique Barack Obama, PHD em Economia Finanças e Negócios em Criptomoedas,  'Temos a maior oportunidade da história de reduzir a pobreza, e apoiar causas sociais e humanitárias usando o advento das criptomoedas, e cumprir o verdadeiro propósito da tecnologia Blockchain'.",
            "index confident": "A plataforma mais disruptiva, descentralizada do mundo",
            "confident text": "Aqui estão alguns motivos para você escolher e indicar a ID SOCIAL UMBRELLA EXCHANGE",
            "Ideals": "Ideiais",
            "Legislation": "Legislação",
            "Obrigations": "Obrigações",
            "pratices": "Boas práticas Socioambientais",
            "legis text": "Não nos limitamos apenas no cumprimento das leis, e nos reforços da fiscalização do ecossistema que fazemos parte",
            "obri text": "Sabemos que não somos apenas uma plataforma com alto valor agregado, ou tão pouco uma Exchange inovadora, disruptiva e inclusiva. Nascemos para ser a própria diferença que esperávamos ver.",
            "prat text": "Desejamos atuar no apoio à eficiência energética, eficiência na utilização de recursos naturais, minerais, hídricos, na preparação para lidar com mudanças climáticas, na qualidade  da destinação de resíduos, atuar no desenvolvimento humano, entre outros temas.",
            "index idiom": "Escolha seu idioma",
            "idiom text": "Nossa proposta é acolher internacionalmente todas as pessoas que desejam a mudança, para isso nossa equipe trabalha para entregar uma plataforma com múltiplas linguagens, permitindo acessibilidade de todo o globo",
            "liquidity": "Liquidez, e contribuição para um mundo melhor",
            "Login Error": "Erro no login",
            "Intern Transfer": "Transferênca interna",
            "Change withdraw method": "Alterar método de saque",
            "Insert your withdraw info": "Insira suas informações de saque",
            "Pix Key": "Chave Pix",
            "Withdraw now": "Saque agora",
            "Account number": "Conta",
            "Agency": "Agencia",
            "Digit": "Digito",
            "Operation": "Operação",
            "Bank": "Banco",
            "Waiting_Approval": "Saque Solicitado",
            "Approved": "Aprovado",
            "Refused": "Recusado",
            "Of": "de",
            "Transfer": "Transferir",
            "Transfer now": "Transferir agora",
            "Transfer History": "Histórico de transferências",
            "Deposit now": "Deposite agora",
            "Please check double to make sure": "Por favor checar para ter certeza",
            "Total_In_Dollar": "Total em dolar",
            "Total_In_Reais": "Total em reais",
            "Nacionality": "Nacionalidade",
            "Document": "CPF/CNPJ",
            "Telephone": "Telefone",
            "Insert Email": "Inserir o Email",
            "Already have an account?": "Já tem uma conta?",
            "Insert mobile phone": "Inserir telefone móvel",
            "Insert phone": "Inserir telefone",
            "Insert Document": "Inserir CPF ou CNPJ",
            "Select your document type": "Selecione seu tipo de documento",
            "Select Your Document": "Selecione seu documento",
            "mobile must be unique": "Celular já cadastrado",
            "Name": "Nome",
            "Sign up your account": "Cadastre sua conta",
            "Username": "Nome de usuário",
            "Password confirm": "Confirmação de senha",
            "Next": "Avançar",
            "Cancel": "Cancelar",
            "Confirm": "Confirmar",
            "External wallet": "Carteira externa",
            "Phone": "Telefone",
            "Recipient user phone": "Telefone do usuário destinatário",
            "Select your type Tranfer": "Selecione o seu tipo de transferência",
            "Recipient user wallet address": "Endereço da carteira do usuário destinatário",
            "Recipient user email": "Email do usuario Destino",
            "Recipient username": "Nome de usuário do destinatário",
            "to": "para",
            "Do you want to transfer": "Você quer transferir",
            "Details": "Detalhes",
            "Check details": "Checar detalhes",
            "Payment details": "Detalhes do pagamento",
            "My data": "Meus dados",
            "My Wallet": "Minha carteira",
            "My documents": "Meus documentos",
            "Bank data": "Dados bancários",
            "Security": "Segurança",
            "Deposit Founds": "Depositar Fundos",
            "Payment method": "Forma de pagamento",
            "Status": "Estado",
            "Date": "Data",
            "Bank Data Listing": "Listagem de Dados Bancarios",
            "Add Bank Account": "Adicionar Conta Bancaria",
            "Add Pix": "Adicionar Pix",
            "Save modifies": "Salvar Alterações",
            "Confirm new password": "Confirmação da nova senha",
            "New password": "Nova Senha",
            "Current password": "Senha atual",
            "First Name": "Primeiro nome",
            "Second Name": "Segundo nome",
            "RG_Document": "Documento RG",
            "Issuing_Body": "Orgão Emissor",
            "Status_Of_Issuance": "Status da Emisão",
            "Father": "Pai",
            "Mother": "Mãe",
            "Type of account": "Tipo de conta",
            "Key Name": "Nome da Chave",
            "Unregistered data": "Dados não registrados",
            "Holder name": "Nome do titular",
            "Finish Registration": "Finalizar Cadastro",
            "Type of key": "Tipo de chave",
            "Key": "Chave",
            "Random Key": "Chave aleatória",
            "Payment data": "Dados do pagamento",
            "Enter your email": "Coloque seu email",
            "User not found": "Usuário não encontrado",
            "Transfer failed": "Falha na transferência",
            "Unexpected error": "Aconteceu um erro inesperado",
            "Enter the transfer amount": "Insira o valor da transferência",
            "Transfer type": "Tipo de transferência",
            "Verify on blockchain": "Verificar na blockchain",
            "Savings Account": "Conta Poupança",
            "Current Account": "Conta Corrente",
            "Recipient user": "Usuário destinatário",
            "Sending user": "Usuário remetente",
            "Sender Transfer History": "Histórico de Transferência do Remetente",
            "Transfer Received History": "Histórico de Transferência Recebidas",
            "Remove": "Remover",
            "Activate": "Ativar",
            "An error has occurred. Please try again.": "Ocorreu um erro. Por favor, tente novamente.",
            "Withdrawal must be greater than or equal to 1 Dollar": "O saque tem que ser maior ou igual a 1 dólar",
            "Make sure you have the": "Certifique-se de ter o",
            "application installed phone.": "aplicativo instalado em seu celular antes de configurar esta opção de segurança.",
            "Google Authenticator Enabled!": "Google Authenticator Ativado!",
            "SCAN THE QRCODE": "ESCANEIE O QRCODE",
            "Second Authentication Factor": "Segundo Fator de Autenticação",
            "2nd Authentication Factor": "2do Fator de Autenticação",
            "Change Password": "Alterar senha",
            "Type the code": "Digite o código",
            "Wrong code, try again.": "Código errado, tente novamente.",
            "Error confirming second authentication factor.": "Erro ao confirmar o segundo fator de autenticação.",
            "Success": "Sucesso",
            "Authenticator successfully removed.": "Autenticador removido com sucesso.",
            "Enter the code sent to the email": "Digite o código enviado para o e-mail:",
            "Confirm Email": "Confirmar e-mail",
            "Resend": "Reenviar",
            "Don't Received?": "Não recebeu?",
            "Error resend email, try again.": "Erro ao reenviar e-mail, tente novamente.",
            "Email sent.": "Email enviado.",
            "Error confirming email, please try again.": "Erro ao confirmar o e-mail, tente novamente.",
            "Value in": "Valor no formato 0000.000,00000",
            "Coin token": "Token da moeda",
            'Transfer date and time in dd/mm/yyyy': 'Data e hora da transferência em dd/mm/aaaa',
            "Received": "Recebida",
            "Sent": "Enviada",
            "Username already registered": "Nome de usuário já cadastrado.",
            "Document already registered": "Documento já cadastrado.",
            "E-mail already registered": "E-mail já registrado.",
            "Error confirming registration, try again.": "Erro ao confirmar o cadastro, tente novamente.",
            "Registration error": "Erro no cadastro.",
            "Cell phone already registered": "Celular ja cadastrado.",
            "Password must be different from the current one.": "A senha deve ser diferente da atual.",
            "Error changing password, please try later.": "Erro ao alterar a senha, tente mais tarde.",
            "Error changing password": "Erro ao alterar a senha",
            "Incorrect current password.": "Senha atual incorreta",
            "Passwords do not match.": "As senhas não coincidem.",
            "Password changed successfully.": "Senha alterada com sucesso.",
            "Enter password with 6 to 21 characters": "Digite a senha com 6 a 21 caracteres",
            "Enter password": "Digite a senha",
            "Enter new password and / or password confirmation": "Digite a nova senha e / ou a confirmação da senha",
            "Request reset": "Solicitar redefinição",
            "Reset password": "Redefinir senha",
            "Back to login": "Voltar para login",
            "Incorrect code.": "Código incorreto.",
            "Incorrect document.": "CPF ou CNPJ incorreto.",
            "Authentication completed successfully.": "Autenticação concluída com sucesso.",
            "Confirmation code.": "Codigo de confirmação.",
            "Login": "Acessar",
            "No Historic": "Sem histórico",
            "Error in withdrawal": "Erro no saque",
            "Back": "Voltar",
            "Withdrawal amount": "Valor de saque",
            "Enter the withdrawal amount": "Digite o valor do saque",
            "Do you wish to continue?": "Você deseja continuar?",
            "Continue": "Continuar",
            "Destination Wallet": "Carteira de destino",
            "tax": "taxa",
            "Attention": "Atenção",
            "Transaction in progress, wait for confirmation in history.": "Transação em andamento, aguarde a confirmação no histórico.",
            "Insert valid phone": "Insira um telefone válido",
            "Sell": "Vender",
            "Buy": "Comprar",
            "Documents": "Documentos",
            "Select the document type": 'Selecione o tipo de documento',
            'Personal_Documents': "DOCUMENTOS PESSOAIS",
            'Dependent_Documents': "DOCUMENTOS DEPENDENTES",
            'Send date': "Data de envio",
            'Document Type': 'Tipo de documento',
            'Upload documents': 'Envio documentos',
            'Upload document': 'Adicionar documento',
            'Search File': 'Buscar Arquivo',
            'Document sent': 'Documento Enviado',
            'No records found': 'Nenhum registro encontrado',
            'Select the file on your computer': 'Selecione o arquivo em seu computador',
            'Maximum file size': 'Tamanho máximo de arquivos:',
            'Change Image': 'Mudar imagem',
            'Data changed': 'Dados alterados',
            'Enter phone number': 'Digite o número do telefone',
            'To complete a transaction, you get your "Google Authenticator" activated.': 'Para concluir uma transação, você ativa o seu "Google Authenticator".',
            'Do you want to continue?': 'Deseja continuar?',
            'Your Bitcoin wallet': "Sua carteira Bitcoin",
            "Don't have a wallet": "Não possui uma carteira",
            "Generate Wallet": "Gerar carteira",
            "Successfully created wallet!": "Carteira criada com sucesso!",
            "No records found.": "Nenhum registro foi encontrado.",
            "General Data": "Dados Gerais",
            "Generate PDF": "Gerar PDF",
            "Sign": "Assinar",
            "Personal data": "Dados Pessoais",
            "Zip Code": "CEP",
            "Street": "Rua",
            "Number": "Número",
            "Neighborhood": "Bairro",
            "Complement": "Complemento",
            "City": "Cidade",
            "State": "Estado",
            "Application_Contratos": "Contratos",
            "Application_Support": "Suporte",
            "Application_AbrirChamado": "Abrir Chamado",
            "Application_Usuario": "Usuário",
            "Application_Data": "Data",
            "Application_Assinatura": "Assinatura",
            "Application_Assunto": "Assunto/Problema",
            "Application_Departamento": "Departamento",
            "Application_Prioridade": "Prioridade",
            "Application_Financeiro": "Financeiro",
            "Application_Baixa": "Baixa",
            "Send_Request_Support": "Enviar pedido de suporte",
            "New_Support_Ticket": "Criar novo Ticket de suporte",
            "Application_StartMarket": "Configurar Mercado",
            "Contract value": "Valor do contrato",
            "Contract_N": "Contrato N°",
            "Contract number": "Número do contrato",
            "Incorrect_Data": "Dados incorretos",
            "Mandatory_Assignment": "Assinatura Obrigatória",
            "Congratulations": "PARABÉNS",
            "Start Date": "Data de Início",
            "Final Date": "Data Final",
            "Type of contract": "Tipo de contrato",
            "Application_Seuscontratos": "Seus contratos",
            "Country": "País",
            "State_issuance": "Estado da Emissão",
            "Title_Page_Policy_And_Privacy": "Política de Privacidade e de Cookies da Infinity Capital Global",
            "Last_Update": "Última atualização: ",
            "Thankyou_Choosing_Infinity_Capital_Global": "Agradecemos por escolher a Infinity Capital Global como seu parceiro financeiro. Nós valorizamos a sua confiança e comprometemo-nos a proteger a sua privacidade e garantir a segurança de suas informações pessoais. Esta Política de Privacidade e de Cookies tem o objetivo de informar claramente como coletamos, usamos, compartilhamos e protegemos seus dados pessoais.",
            "Consent": "Consentimento",
            "By_Using_Our_Services": "Ao utilizar nossos serviços, você concorda com a coleta e o uso de suas informações pessoais de acordo com esta Política de Privacidade e de Cookies. Caso não concorde com os termos desta política, recomendamos que não continue utilizando nosso site e serviços.",
            "About_The_Company": "Sobre a empresa",
            "Infinity_Capital_Global_Is_A_Financial_Platform": "A Infinity Capital Global é uma plataforma financeira focada em facilitar o acesso ao mercado de Criptomoedas e Ações em todo o mundo. Nosso objetivo é proporcionar uma experiência robusta e simples, oferecendo serviços financeiros, mentorias e ferramentas para facilitar a vida dos nossos clientes no dia a dia de suas operações.",
            "Data_Collect": "Coleta de dados",
            "In_Order_To_Provide": "Para fornecer serviços financeiros personalizados e garantir a segurança de nossos clientes, podemos coletar as seguintes informações pessoais: Informações de identificação: Nome completo, documento de identificação (CPF, RG ou outro documento legalmente válido), data de nascimento e nacionalidade. Informações de contato: Endereço residencial, endereço de e-mail, número de telefone e outras informações para contato. Informações financeiras: Número de conta bancária, informações sobre transações e histórico de pagamentos. Informações sobre investimentos: Dados sobre investimentos, portfólio e preferências de investimento. Informações de login: Nome de usuário, senha e outras informações necessárias para autenticação em nossa plataforma.",
            "Purposes_Data_Processing": "Finalidades do Tratamento de Dados",
            "The_Personal_Data_Collected": "Aos dados pessoais coletados são utilizados para os seguintes propósitos: Fornecer serviços financeiros solicitados pelo cliente, incluindo a execução de transações e investimentos. Cumprir com obrigações legais, regulatórias e contratuais aplicáveis a serviços financeiros. Personalizar nossa comunicação e fornecer atualizações sobre novos produtos, ofertas especiais, eventos e informações relevantes relacionadas aos serviços que você utiliza. Melhorar a qualidade de nossos serviços e garantir a segurança dos nossos clientes e da nossa plataforma contra atividades fraudulentas ou suspeitas.",
            "Data_Sharing": "Compartilhamento de Dados",
            "Infinity_Capital_Global_Will_Not_Share": "A Infinity Capital Global não irá compartilhar, vender ou transferir seus dados pessoais a terceiros, exceto conforme descrito nesta política ou com seu consentimento expresso para fins específicos. Podemos compartilhar seus dados pessoais com: Prestadores de serviços terceirizados: Podemos utilizar empresas terceirizadas para fornecer serviços em nosso nome, como processamento de pagamentos, análise de dados, suporte ao cliente, entre outros. Essas empresas têm acesso limitado às suas informações e estão proibidas de utilizá-las para qualquer outra finalidade que não seja a prestação dos serviços contratados. Autoridades governamentais: Em cumprimento de obrigações legais e regulatórias, podemos ser obrigados a compartilhar suas informações com autoridades governamentais ou órgãos reguladores.",
            "Data_Security": "Segurança de Dados",
            "We_Adopt_Technical": "Adotamos medidas técnicas, organizacionais e de segurança para proteger seus dados pessoais contra acesso não autorizado, perda, alteração ou divulgação. Nossos colaboradores são treinados para tratar suas informações de forma confidencial e estão sujeitos a obrigações de confidencialidade.",
            "Cookies_Tracking_Technologies": "Cookies e Tecnologias de Rastreamento",
            "Our_Website_Uses_Cookies": "Nosso site utiliza cookies e tecnologias de rastreamento para melhorar sua experiência de navegação e coletar informações sobre o uso do site. Esses cookies são utilizados para lembrar suas preferências, analisar tendências e padrões de navegação, personalizar conteúdo e exibir anúncios relevantes. Você pode gerenciar suas preferências de cookies nas configurações do seu navegador. Ao continuar utilizando nosso site sem modificar as configurações de cookies, você concorda com o uso de cookies.",
            "Your_Rights": "Seus direitos",
            "You_Have_Rights": "Você tem direitos em relação aos seus dados pessoais: Acesso: Você pode solicitar informações sobre os dados que possuímos sobre você. Retificação: Caso suas informações estejam incorretas ou desatualizadas, você pode solicitar a correção. Exclusão: Você tem o direito de solicitar a exclusão de seus dados, a menos que haja obrigações legais ou contratuais que impeçam tal exclusão. Portabilidade: Seus dados podem ser transferidos para outra empresa, caso seja tecnicamente viável. Oposição: Em certas circunstâncias, você pode se opor ao processamento de seus dados pessoais.",
            "Minors": "Menores de Idade",
            "Our_Services_Are_Not_Intended": "Nossos serviços não se destinam a menores de 18 anos. Caso tenhamos conhecimento de que coletamos dados pessoais de um menor de idade, tomaremos medidas para excluir esses dados o mais rápido possível.",
            "Application_O_Seriousness": "Aplicação da Seriedade e o Cliente em Primeiro Lugar",
            "At_Infinity_Capital_Global": "Na Infinity Capital Global, aplicamos seriedade em tudo o que fazemos. O cliente está sempre em primeiro lugar, e nosso compromisso é fornecer serviços financeiros de alta qualidade, suporte, mentorias e ferramentas para facilitar a sua experiência financeira.",
            "Changes_To_The_Privacy_Policy": "Alterações na Política de Privacidade",
            "We_Reserve_The_Right_To_Update": " Reservamo-nos o direito de atualizar esta Política de Privacidade periodicamente para refletir mudanças em nossas práticas de privacidade. Recomendamos que você reveja esta política regularmente para estar ciente de eventuais atualizações.",
            "Contact_And_Support": "Contato e Suporte",
            "In_Case_Of_Douts": "Em caso de dúvidas, inseguranças ou qualquer outra necessidade, nossos clientes podem entrar em contato conosco através dos seguintes meios: Email: contato@infinitycapital.global Telefone: 55 11 99999-0000 WhatsApp: [Número do WhatsApp]",
            "Monday to Friday from 09h to 18h": "Segunda à Sexta-feira das 09h às 18h",
            "Useful links": "Links úteis",
            "Privacy Policy": "Política de privacidade",
            "Terms of use": "Termos de uso",
            "Active": "Ativo",
            "Historic": "Histórico",
            "Our story": "Nossa história",
            "How it works": "Como funciona",
            "Contact": "Contato",
            "Description_Infinity_Capital": "Infinity Capital é a marca registrada de sites e plataformas digitais operados pela INFINITY CAPITAL MANAGEMENT LTD. e suas empresas separadas, mas afiliadas, Infinity Capital Securities LLC, Infinity Capital, Infinity Capital Digital Assets LLC, Infinity Capital Cash LLC. 43-45 Dorset Street, Londres, W1U 7NAW 1 U 7 NA - REINO UNIDO",
            "Application_EfetuarSaque": "Efetuar saque",
            "Total wallet": "Total da carteira",
            "Withdrawal method": "Método de saque",
            "Select a bank account": "Selecione uma conta bancária",
            "Select a pix key": "Selecione uma chave pix",
            "Your robots": "Seus robôs",
            "application_textrodape": "A Infinity Capital fornecerá ao cliente suporte comercialmente razoável e informações pertinentes para permitir que satisfaça os requisitos de todas as agências reguladoras/licenciamento aplicáveis e estatutos associados, incluindo a Segurança da FSA (Seychelles), Registro FCA: 03869255"
        }
    },
    en: {
        translation: {
            "Application_Contato": "Contact",
            "Application_NaoMostrarSaldoZero": "Hide zero balance",
            "Application_TotalExchange": "Assets on Exchange",
            "Application_SaldoAtivos": "Actives",
            "Application_Todos": "All",
            "Application_FalarComSeugerente": "Talk to Manager",
            "Application_Configurations": "Account Settings",
            "Application_Referal": "Referral Program",
            "Application_TextoReferal": "Invite friends and earn up to 100 USDT and a 5% commission",
            "Application_Inbox": "Inbox",
            "Application_TextoInbox": "Check your messages sent by our team",
            "Application_TextoNotifications": "View all your notifications.",
            "Application_MaisOpcoes": "+ Options",
            "Application_TextoAbrirChamado": "Open a support ticket for our team and get a quick response.",
            "Application_TextoSuporte": "View all your support requests.",
            "Application_TextoSaqueFiat": "Quickly withdraw your funds to your bank account using PIX, TED, or DOC",
            "Application_TextoEmprestimo": "Request a loan using your available margin and pay securely and comfortably.",
            "Application_TextoSaqueCrypto": "Quickly withdraw your funds to your Crypto Wallet",
            "Application_DepositCrypto": "Deposit Crypto (USDT, BTC), and more",
            "Application_TextoDepositar": "Easily and securely deposit fiat currency",
            "Application_TextoDepositarCrypto": "Easily and securely deposit Crypto",
            "Application_TextoMercados": "Trade smarter with our various automated strategies - easy, fast, and reliable",
            "Application_TextStocks": "Overview of the stock market with the help of our AI",
            "Application_TextCrypto": "Overview of the crypto market and its assets",
            "Application_SelectLanguage": "Select your language",
            "Application_Cadastro": "Create Account",
            "Application_InvoiceNumber": "Invoice Number",
            "Application_InvoiceDate": "Invoice Date",
            "Application_Nivel": "Support Level",
            "Application_Notifications": "Notifications",
            "Applications_TradersMercado": "Market Traders",
            "Application_Qtd": "Qty",
            "Application_Total": "Total",
            "Application_TodosTickes": "All Tickets",
            "Application_Deposito": "Deposit",
            "Application_Saque": "Withdraw",
            "Application_ValorContrato": "Contract Value",
            "Application_AguardandoEmprestimo": "Under Review",
            "Application_Aprovado": "Approved",
            "Application_Liberado": "Released",
            "Application_Cancelado": "Cancelled",
            "Application_NaoAprovado": "Not Approved",
            "Application_MsgEmprestimo": "Your margin for loan application is up to {{margemEmprestimo}} USD, and you can pay in a maximum of {{qtdParcel}}x of {{ValorParcel}}",
            "Application_Emprestimos": "Loans",
            "Application_SolicitarEmprestimo": "Request Loan",
            "Application_MargemEmprestimo": "Margin for loan",
            "Application_Score": "Score",
            'Application_DadosInexistentes': "Nonexistent Data or no contracts for you",
            "Application_ContratoN": "Contract No.",
            "Application_QtdParcelas": "Qty Installments",
            "Application_Opcoes": "Options",
            "Application_informacao": "Information",
            "Application_Contrato": "CONTRACT",
            "Application_DetalhesPagamentos": "Details of payment of contract No.",
            "Application_Shares": "SHARES",
            "Application_VolumeStock": "VOLUME",
            "Application_RSIStock": "RSI",
            "Application_Posicao": "POSITION",
            "Application_MediasMoveis": "MOVING AVERAGES",
            "Application_CodBank": "BANK CODE",
            "Application_PassCod": "Pass COD",
            "Application_CheckOperar": "Operate with market dynamics",
            "Application_Comprada": "BOUGHT",
            "Application_Vendedora": "SELLER",
            "Application_CFDS": "CFDS",
            "Application_IniciarOperacao": "START OPERATION",
            "Application_ValorTotal": "Purchase Value",
            "Application_ConfirmarOperarStock": "OPERATE WITH MARKET DYNAMICS",
            "Application_Capital": "BALANCE",
            "Application_SaldoStock": "BALANCE",
            "Application_SetupStocks": "Configure your Software",
            "Application_DataDeNascimento": "Date of Birth",
            "Application_StartMarketStocks": "Configure Software",
            "No_Response_Found": "No data at the moment, waiting for agents",
            "Send_Reply_Support": "Send reply",
            "Send_Reply": "Sending reply",
            "Application_Responder": "Reply to ticket",
            "Assunto_Required_Error": "Subject field required",
            "Departamento_Required_Error": "Department field required",
            "Prioridade_Required_Error": "Priority field required",
            "Conteudo_Required_Error": "Content field required",
            "Application_Anexar_Arquivo": "Attach file",
            "File_Required_Error": "Attachment field required",
            "Select_Prioridade": "Select priority",
            "Select_Departamento": "Select department",
            "Application_Supporte": "Support",
            "Sending_Request": "Sending request",
            "Application_msgStopRobo2": "You can safely stop your robot without capital losses",
            "Application_DigiteValor": "Enter the value you want to convert to balance",
            "Application_msgSaque": "Confirm the withdrawal of {{valor}} from your available capital?",
            "Application_msgSaqueConfirmado": "The amount of {{valor}} is now available for withdrawal in the system. Thank you!",
            "Application_MsgSaqueNaoHabilitado": "Robot in operation, contact your analyst, as profits may be used for trading margin.",
            "Application_BtnSacarDisponivel": "Release for withdrawal",
            "Application_SelecioneCarteira": "Select the wallet",
            "Application_ContaAwaiterificada": "Documents submitted, awaiting verification by the team",
            "Application_msgDepositarCrip": "To make a deposit via Cryptocurrency, please specify the type of currency you wish to use below.",
            "Application_Deposit": "Deposit",
            "Application_euquerodepositar": "I want to deposit",
            "Application_viaRede": "via network",
            "Application_SelecionaUmaRede": "Select a network",
            "Application_Cryptos": "Cryptos",
            "Application_ValorDeposito": "Enter the deposit amount",
            "Application_ValorMinimo": "Minimum deposit amount",
            "Application_Confirmacoes": "Confirmations on the network",
            "Application_ConfirmarDeposito": "Confirm Deposit",
            "Application_Stock": "Stocks",
            "Application_Arbitragem": "Arbitrage",
            "Application_redeChain": "Select network",
            "Application_confirm": "Confirm",
            "Application_cancel": "Cancel",
            "Application_stop": "Stop",
            "Application_Atencion": "Attention",
            "Application_msgStopRobo": "Do you really want to stop this Robot? Stopping it may result in market and asset losses.",
            "Application_textMissao": "Founded in 2015 by visionary entrepreneurs, Infinity Capital's mission is to lead the transformation of the global financial markets. Starting in gold mining, we combine tradition and innovation to achieve success and recognition. With agility, we embrace cryptocurrencies and blockchain technology, expanding our influence. Through artificial intelligence, we revolutionize our processes and trading, keeping us agile in the face of changes. Our cutting-edge platform attracts diverse customers, and our headquarters in London symbolizes our global presence. We are driven by the relentless pursuit of excellence, uniting tradition and innovation to define the global financial future.",
            "Application_textVisao": "Our vision is to lead the transformation of the global financial landscape. By combining tradition and innovation, we aim to redefine standards in mining, cryptocurrencies, and AI. We want to be a hub of collaboration, providing agile decision-making through AI and establishing an impactful global presence. Our culture of excellence and innovation is fueled by the passion to challenge limits and create an unlimited financial future.",
            "Application_QuemSomos_01": "On November 1, 1999, a group of visionaries founded Infinity Capital, an innovative company that began its operations with a focus on gold mining and participation in financial market trading. With a unique and technologically advanced approach, the company quickly stood out in these sectors, using modern and efficient methods to extract precious metal and gain credibility, delivering results to its investors.",
            "Application_QuemSomos_02": "On November 22, 2005, the company was licensed by the UK regulatory body FCA, expanding its horizons to encompass institutional capitals from major liquidity providers, enabling collaboration with banks through stocks, commodities, and indices.",
            "Application_QuemSomos_03": "However, as the world witnessed the rise of cryptocurrencies and blockchain technology, Infinity Capital saw an opportunity to expand and diversify its business. In a strategic move in line with this trend, the company was incorporated into the JP Morgan group, one of the most influential and respected financial institutions globally.",
            "Application_QuemSomos_04": "Taking on the role of director at Infinity Capital, alongside David Brown, was Denis Costa, an experienced leader with a vision for innovation and technology. Under his guidance and with the extensive experience of JP Morgan, the company took a significant leap in its evolution.",
            "Application_QuemSomos_05": "From that moment, Infinity Capital directed its resources to the development of AI-driven robots, aiming for the future. These robots were programmed to analyze market data, identify trends, and execute cryptocurrency trades in an automated and precise manner. This pioneering approach quickly became one of Infinity Capital's trademarks, establishing it as a leader in the field of algorithmic cryptocurrency trading.",
            "Application_QuemSomos_06": "As Infinity Capital's robots continued to deliver exceptional results amid market volatility, the company solidified its position as one of the largest exchanges in the international market. Its trading platform became known for speed, security, and efficiency, attracting traders and investors from around the world.",
            "Application_QuemSomos_07": "To sustain its growth and establish itself as an iconic name in the industry, Infinity Capital chose the impressive Heron Tower, located at 110 Bishopsgate, London EC2N 4AY, United Kingdom, as its branch. This strategic location not only reflects the company's prominent position but also symbolizes its vision of raising the standard for cryptocurrency-based financial services.",
            "Application_QuemSomos_08": "Over time, Infinity Capital continued to innovate and expand its horizons, collaborating with AI and blockchain experts, staying at the forefront of financial technology. Its history is marked by overcome challenges, notable achievements, and an unwavering commitment to excellence. Thus, Infinity Capital continues to shape the future of finance, exploring new frontiers and paving the way for a truly revolutionary era of trading and investment.",
            "Application_QuemSomos_09": "",
            "Application_QuemSomos_10": "",
            "Application_textValores1": "Sustainable Innovation: We create lasting solutions through change and technology.",
            "Application_textValores2": "Unwavering Integrity: We maintain high ethical standards for trust and transparency.",
            "Application_textValores3": "Empowering Collaboration: We value diverse ideas and grow together.",
            "Application_textValores4": "Agile Adaptation: Rapid and effective response to market changes.",
            "Application_textValores5": "Continuous Excellence: Relentless pursuit of improvement in all aspects.",
            "Application_textValores6": "Social and Environmental Responsibility: We make a positive contribution to communities and the environment.",
            "Application_textValores7": "Passion for Growth: A passion for learning keeps us innovative and growing.",
            "Application_Text1": "a team waiting for you.",
            "Application_Text2": "A globally recognized brand",
            "Application_ConteComEquipe": "Count on our team to manage your assets and investments",
            "Application_RegistrarAgora": "Register Now",
            "Application_Jatenhoconta": "I already have an account",
            "Application_QuemSomos": "About Us",
            "Join": "Join",
            "Application_TxtQuemSomos": "A platform focused on facilitating access to the world of Cryptocurrencies and Stocks.",
            "Application_Txt2QuemSomos": "Primarily aiming for inclusion and constantly improving access to the crypto market for everyone. Infinity Global offers solutions that go beyond traditional tools. We are prepared for the era of financial inclusion, and from basic to advanced, we are ready to open the doors of this market for you.",
            "Application_Servicos": "Services",
            "Application_Market": "Market Prices",
            "Application_agentes": "Agents",
            "txt_agentes": "Agents located around the world to provide platinum-level service for you.",
            "Application_plataforma": "Our Platform",
            "Application_Txtplataforma": "A robust and user-friendly platform for you to enjoy the best and most secure experience.",
            "Application_txtApi": "The API is designed to provide an easy and efficient way to integrate your trading application into our platform.",
            "Description_Bottom": "Infinity Capital is the registered trademark of websites and digital platforms operated by INFINITY CAPITAL MANAGEMENT LTD. and its separate but affiliated companies, Infinity Capital Securities LLC, Infinity Capital, Infinity Capital Digital Assets LLC, Infinity Capital Cash LLC. Heron Tower, 110 Bishopsgate, London EC2N 4AY, United Kingdom",
            "Application_Mercados": "Markets",
            "Application_SetupMercados": "Set up your robot",
            "Button_Success_Confirure_Robot": "Start the process",
            "Message_Select_Option": "Select an option",
            "Unavailable_Balance": "Unavailable balance",
            "Message_Invalide_Token": "Invalid token",
            "Application_Pais": "Country: ",
            "Application_SejaBemVindo": "Welcome",
            "Application_ContaSemVerificacao": "Your account has not been verified yet, your access is limited to the basic level. To normalize your access, select the manage tab and update your details to complete the account verification.",
            "Application_CadastradoEm": "Registered on: ",
            "Application_exchangePro": "Exchange",
            "Application_Modelo": "Options",
            "Application_Perfil": "Settings",
            "Application_Agressivo": "Aggressive",
            "Application_Moderador": "Moderate",
            "Application_Convservador": "Conservative",
            "Application_Selecionar": "Select",
            "Application_OrdemPendente": "Immediate Operations",
            "Application_ExecucaoInstantanea": "Analyze & Operate",
            "Application_Saldo": "Capital",
            "Application_TipoStop": "Stop Type",
            "Application_Valor": "Value",
            "Application_Lucros": "Total Profit",
            "Application_Disponivel": "Remaining Profit",
            "Application_Porcentagem": "Percentage %",
            "Application_StopWin": "Profit",
            "Application_StopLoss": "Stop Loss",
            "Application_BBS": "EMA",
            "Application_RSI": "BOLLINGER",
            "Application_MACD": "FRACTAL",
            "Application_Volume": "OPERATING MARGIN",
            "Application_DigiteToken": "Enter the Token sent by your manager.",
            "Withdraw Fiat History": "Fiat Withdrawal History",
            "Submit": "Submit",
            "Application_EnviarDocumentos": "Upload Documents",
            "Application_ContaVerificada": "Verified Account",
            "Apllication_verificacaoConta": "Account Verification",
            "Application_NaoAssinado": "Not Signed",
            "Application_Assinado": "Signed",
            "Application_Assinado_Sucesso": "SIGNED SUCCESSFULLY!",
            "Application_Tickets": "Your Tickets",
            "Application_Status": "Status",
            "Application_Acoes": "Actions",
            "Application_Aberto": "Open",
            "Application_emAndamento": "In Progress",
            "Application_AguardandoStaff": "Awaiting Staff",
            "Application_AguardandoMembro": "Awaiting Member",
            "Application_Fechado": "Closed",
            "Application_Abrir": "Open",
            "Application_Fechar": "Close",
            "Application_USD": "USD",
            "Application_CopiarPix": "Copy Pix Code",
            "Application_Administracao": "Administration",
            "Application_Compilance": "Compliance",
            "Application_Brokers": "Brokers & Agents",
            "Application_Media": "Average",
            "Application_Alta": "High",
            "Application_Informacoes": "Request Information",
            "Application_TicketNumber": "Ticket N",
            "Data_Ticket": "Ticket Data",
            "Application_Enviado": "Sent on",
            "Application_Cliente": "Client",
            "Application_VoceEstaSendo": "You are being referred by: ",
            "Application_Naoexiste": "This user does not exist in our database, please check the data",
            "Application_WalletUSD": "Your USD Wallet",
            "Application_OperacaoStop": "Your Robot is in operation, please contact your analyst",
            "Application_AtivoInvestments": "Active Investment",
            "Application_Ordens": "Orders",
            "Application_Investimento": "Investment: ",
            "Application_Available": "Remaining Profit",
            "Application_Retorno": "Total Profit: ",
            "Application_Robo": "My Robots",
            "Application_Hash": "Hash",
            "Application_ContractID": "Reference Contract",
            "Application_Visualizar": "View",
            "Application_RelatorioGanhos": "Earnings Report",
            "Application_Ativos": "Assets",
            "Application_Aguardando": "Awaiting",
            "Application_Finalizado": "Completed",
            "Application_ContratoAtual": "Hash Contract",
            "Application_HashTransaction": "Transaction Hash",
            "Application_Ganhos": "Earnings",
            "Application_priceBuy": "Price Buy",
            "Application_priceSell": "Price Sell",
            "Application_Tipo": "Type",
            "Application_Gain": "Gain",
            "Application_Loss": "Loss",
            "Application_Banner_Legenda1": "Banner Caption 01",
            "What_Shipping": "What shipping methods are available?",
            "How_Long": "How long will it take to receive my package?",
            "How_Do_I_Track": "How do I track my order?",
            "Sign up": "Sign up",
            "Create_Account": "Create an Account",
            "Join_Platform": "Join our platform",
            "Create your account and start investing": "Create your account and start investing!",
            "Home": "Home",
            "Assets": "Assets",
            "Guide": "Guide",
            "Language": "Language",
            "News": "News",
            "Trade with ": "Trade with ",
            "Buys and sell criptocurrencies": "Buy and sell cryptocurrencies",
            "Fast and safe": "Fast and safe",
            "Be part of the revolution": "Be part of the revolution",
            "English": "English",
            "Portuguese": "Portuguese",
            "Spanish": "Spanish",
            "Your wallet": "Your wallet",
            "Deposit_Request": "Deposit Request",
            "Index Price": "Index Price",
            "24h Change": "24h Change",
            "1h Change": "1h Change",
            "Market Cap": "Market Cap",
            "24h Volume": "24h Volume",
            "Balance": "Balance",
            "Size": 'Size',
            "Final Value": "Final Value",
            "Average price": "Average Price",
            "Time": "Time",
            "Description": "Description",
            "Order History": "Order History",
            "Symbol": "Symbol",
            "Price": "Price",
            "Last Modify": "Last Modify",
            "Market": "Market",
            "Balance in wallet": "Balance in wallet",
            "Buy Now": "Buy Now",
            "Sell Now": "Sell Now",
            "Pairs": "Pairs",
            "Last Price": "Last Price",
            "Change": "Change",
            "Trade History": "Trade History",
            "Order not placed": "Order not placed",
            "Order placed": "Order placed",
            "You sold": "You sold",
            "You bought": "You bought",
            "Order value must be greater than 0": "Order value must be greater than 0",
            "Insufficient funds": "Insufficient funds",
            "Hello": "Hello",
            "Trades made": "Trades made",
            "Balance available": "Balance available",
            "Deposit": "Deposit",
            "Account": "Account",
            "Logout": "Logout",
            "Pay to receive your funds": "Pay to receive your funds",
            "Value": "Value",
            "Type": "Type",
            "Code": "Code",
            "Pending": "Pending",
            "Paid": "Paid",
            "Expired": "Expired",
            "Canceled": "Canceled",
            "Reversed": "Reversed",
            "Deposit not placed": "Deposit not placed",
            "Deposit value must be greater than 0": "Deposit value must be greater than 0",
            "Confirmation URL": "Confirmation URL",
            "Deposit fiat": "Deposit fiat",
            "Deposit crypto": "Deposit crypto",
            "Bankslip": "Bankslip",
            "Credit Card": "Credit Card",
            "Access the bankslip": "Access the bankslip",
            "Access QR code on PicPay": "Access QR code on PicPay",
            "Select your bank": "Select your bank",
            "Your cryptocurrency wallet": "Your cryptocurrency wallet",
            "Show": "Show",
            "Verified on blockchain": "Verified on blockchain",
            "Hide": "Hide",
            "Use this wallet to receive": "Use this wallet to receive",
            "Your Ethereum wallet": "Your Ethereum wallet",
            "This value was added to your funds": "This value was added to your funds",
            "Withdraw crypto": "Withdraw crypto",
            "Withdraw fiat": "Withdraw fiat",
            "Address": "Address",
            "Copy the address of your external wallet": "Copy the address of your external wallet",
            "Withdraw": "Withdraw",
            "Select your token": "Select your token",
            "An error occurred on your deposit": "An error occurred on your deposit",
            "Withdraw value must be greater than 0 and enter a valid address": "Withdraw value must be greater than 0 and enter a valid address",
            "Withdraw History": "Withdraw History",
            "Receiver address": "Receiver address",
            "Eth withdraws are not working at the moment": "Eth withdraws are not working at the moment",
            "index join": "Join the ",
            "index subtitle": "Buy, sell, and invest in cryptocurrencies, tokens, and crypto assets on the world's first exchange with wallets and coins with social responsibility and purpose. It's the only exchange with its ecosystem, enhancing the investments of all its members.",
            "index steps": "Every great journey starts with the first 3 steps, right? Come with us!",
            "index createAc": "Create your account here, ease is our goal",
            "Enjoy": "Enjoy",
            "Deposit your funds": "Deposit your funds",
            "Start to buy and sell": "Start buying and selling",
            "index thirdTitle": "Customize your umbrella to protect your investments",
            "index thirdIndexSubtitle": "ID UMBRELLA SOCIAL ID has a variety of features and technological tools that apply knowledge organization, sharing, and diffusion to generate the much-awaited collective wisdom. Through this concept, we can help introduce beginners and even collaborate to further enhance the experts.",
            "Manage your portfolio": "Manage your portfolio",
            "manage text": "Invest, buy and sell digital assets, and track them in one place",
            "Recurring buys": "Recurring buys",
            "recurring text": "Invest in the disruptive economy slowly and securely. Stay updated on news and suggestions through this portal.",
            "Vault protection": "Vault protection",
            "vault text": "For greater security, store your investments in your personal portfolio. Enjoy the flexibility of having your assets available 24/7, anywhere in the world.",
            "Mobile": "Mobile",
            "mobile text": "Keep an eye on the markets through the ID SOCIAL UMBRELLA EXCHANGE apps. System of the ID IDENTIFICATION group",
            "Deposit History": "Deposit History",
            "Password": "Password",
            "Sign in": "Sign in",
            "Forgot Password?": "Forgot Password?",
            "Remember me": "Remember me",
            "Don't have an account?": "Don't have an account?",
            "liquidity text": "In addition to accessing your investment resources from anywhere in the world, you are already contributing to a better world. In this world, resources are meant to be used by people. In the model we are dismantling, people are enslaved by money. What has more value to you? Money or a human being?",
            "index trust": "Trust in our partners, investors, and our values",
            "trust text": "We were born with over 50,000 investor members, distributed across more than 160 countries, being the world's first exchange to require each member to support the Human Rights Treaties pact indicated by the UN. Because in the vision of our founder, Henrique Barack Obama, PhD in Economics, Finance, and Cryptocurrency Business, 'We have the greatest opportunity in history to reduce poverty and support social and humanitarian causes using cryptocurrencies and fulfill the true purpose of blockchain technology.'",
            "index confident": "The world's most disruptive, decentralized platform",
            "confident text": "Here are some reasons for you to choose and recommend ID SOCIAL UMBRELLA EXCHANGE",
            "Ideals": "Ideals",
            "Legislation": "Legislation",
            "Obligations": "Obligations",
            "practices": "Environmental and Social Best Practices",
            "legis text": "We do not limit ourselves to complying with the laws and reinforcing the ecosystem we are part of.",
            "obri text": "We know that we are not just a platform with high added value, nor are we merely an innovative, disruptive, and inclusive exchange. We were born to be the difference we hoped to see.",
            "prat text": "We aim to act in support of energy efficiency, efficient use of natural resources, minerals, water, readiness to deal with climate change, quality of waste disposal, human development, and other topics.",
            "index idiom": "Choose your language",
            "idiom text": "Our proposal is to internationally welcome all people who desire change, for this our team works to deliver a platform with multiple languages, allowing accessibility worldwide.",
            "liquidity": "Liquidity and Contribution to a Better World",
            "Login Error": "Login Error",
            "Internal Transfer": "Internal Transfer",
            "Change withdraw method": "Change Withdraw Method",
            "Insert your withdraw info": "Insert Your Withdrawal Info",
            "Pix Key": "Pix Key",
            "Withdraw now": "Withdraw Now",
            "Account number": "Account Number",
            "Agency": "Agency",
            "Digit": "Digit",
            "Operation": "Operation",
            "Bank": "Bank",
            "Waiting_Approval": "Withdrawal Requested",
            "Approved": "Approved",
            "Refused": "Refused",
            "Of": "Of",
            "Transfer": "Transfer",
            "Transfer now": "Transfer Now",
            "Transfer History": "Transfer History",
            "Deposit now": "Deposit Now",
            "Please check double to make sure": "Please double-check to make sure",
            "Total_In_Dollar": "Total in Dollars",
            "Total_In_Reais": "Total in Reais",
            "Nationality": "Nationality",
            "Document": "CPF/CNPJ",
            "Telephone": "Telephone",
            "Insert Email": "Insert Email",
            "Already have an account?": "Already have an account?",
            "Insert mobile phone": "Insert Mobile Phone",
            "Insert phone": "Insert Phone",
            "Insert Document": "Insert CPF or CNPJ",
            "Select your document type": "Select Your Document Type",
            "Select Your Document": "Select Your Document",
            "mobile must be unique": "Mobile already registered",
            "Name": "Name",
            "Sign up your account": "Sign Up for Your Account",
            "Username": "Username",
            "Password confirm": "Password Confirmation",
            "Next": "Next",
            "Cancel": "Cancel",
            "Confirm": "Confirm",
            "External wallet": "External Wallet",
            "Phone": "Phone",
            "Recipient user phone": "Recipient User Phone",
            "Select your transfer type": "Select Your Transfer Type",
            "Recipient user wallet address": "Recipient User Wallet Address",
            "Recipient user email": "Recipient User Email",
            "Recipient username": "Recipient Username",
            "to": "to",
            "Do you want to transfer": "Do You Want to Transfer",
            "Details": "Details",
            "Check details": "Check Details",
            "Payment details": "Payment Details",
            "My data": "My Data",
            "My Wallet": "My Wallet",
            "My documents": "My Documents",
            "Bank data": "Bank Data",
            "Security": "Security",
            "Deposit Funds": "Deposit Funds",
            "Payment method": "Payment Method",
            "Status": "Status",
            "Date": "Date",
            "Bank Data Listing": "Bank Data Listing",
            "Add Bank Account": "Add Bank Account",
            "Add Pix": "Add Pix",
            "Save modifies": "Save Changes",
            "Confirm new password": "Confirm New Password",
            "New password": "New Password",
            "Current password": "Current Password",
            "First Name": "First Name",
            "Second Name": "Second Name",
            "RG_Document": "ID Document",
            "Issuing_Body": "Issuing Authority",
            "Status_Of_Issuance": "Issuance Status",
            "Father": "Father",
            "Mother": "Mother",
            "Type of account": "Account Type",
            "Key Name": "Key Name",
            "Unregistered data": "Unregistered Data",
            "Holder name": "Holder Name",
            "Finish Registration": "Finish Registration",
            "Type of key": "Key Type",
            "Key": "Key",
            "Random Key": "Random Key",
            "Payment data": "Payment Data",
            "Enter your email": "Enter Your Email",
            "User not found": "User not found",
            "Transfer failed": "Transfer failed",
            "Unexpected error": "Unexpected error",
            "Enter the transfer amount": "Enter the transfer amount",
            "Transfer type": "Transfer Type",
            "Verify on blockchain": "Verify on blockchain",
            "Savings Account": "Savings Account",
            "Current Account": "Current Account",
            "Recipient user": "Recipient User",
            "Sending user": "Sending User",
            "Sender Transfer History": "Sender Transfer History",
            "Transfer Received History": "Transfer Received History",
            "Remove": "Remove",
            "Activate": "Activate",
            "An error has occurred. Please try again.": "An error has occurred. Please try again.",
            "Withdrawal must be greater than or equal to 1 Dollar": "Withdrawal must be greater than or equal to 1 Dollar",
            "Make sure you have the": "Make sure you have the",
            "application installed phone.": "application installed on your phone before configuring this security option.",
            "Google Authenticator Enabled!": "Google Authenticator Enabled!",
            "SCAN THE QRCODE": "SCAN THE QRCODE",
            "Second Authentication Factor": "Second Authentication Factor",
            "2nd Authentication Factor": "2nd Authentication Factor",
            "Change Password": "Change Password",
            "Type the code": "Type the code",
            "Wrong code, try again.": "Wrong code, try again.",
            "Error confirming second authentication factor.": "Error confirming second authentication factor.",
            "Success": "Success",
            "Authenticator successfully removed.": "Authenticator successfully removed.",
            "Enter the code sent to the email": "Enter the code sent to the email:",
            "Confirm Email": "Confirm Email",
            "Resend": "Resend",
            "Don't Received?": "Don't Received?",
            "Error resend email, try again.": "Error resending email, try again.",
            "Email sent.": "Email sent.",
            "Error confirming email, please try again.": "Error confirming email, please try again.",
            "Value in": "Value in",
            "Coin token": "Coin Token",
            "Transfer date and time in dd/mm/yyyy": "Transfer date and time in dd/mm/yyyy",
            "Received": "Received",
            "Sent": "Sent",
            "Username already registered": "Username already registered",
            "Document already registered": "Document already registered",
            "E-mail already registered": "E-mail already registered",
            "Error confirming registration, try again.": "Error confirming registration, try again.",
            "Registration error": "Registration error",
            "Cell phone already registered": "Cell phone already registered",
            "Password must be different from the current one.": "Password must be different from the current one.",
            "Error changing password, please try later.": "Error changing password, please try later.",
            "Error changing password": "Error changing password",
            "Incorrect current password.": "Incorrect current password",
            "Passwords do not match.": "Passwords do not match.",
            "Password changed successfully.": "Password changed successfully",
            "Enter password with 6 to 21 characters": "Enter password with 6 to 21 characters",
            "Enter password": "Enter password",
            "Enter new password and / or password confirmation": "Enter new password and / or password confirmation",
            "Request reset": "Request reset",
            "Reset password": "Reset password",
            "Back to login": "Back to login",
            "Incorrect code.": "Incorrect code.",
            "Incorrect document.": "Incorrect CPF or CNPJ.",
            "Authentication completed successfully.": "Authentication completed successfully.",
            "Confirmation code.": "Confirmation code.",
            "Login": "Login",
            "No Historic": "No History",
            "Error in withdrawal": "Withdrawal error",
            "Back": "Back",
            "Withdrawal amount": "Withdrawal amount",
            "Enter the withdrawal amount": "Enter the withdrawal amount",
            "Do you wish to continue?": "Do you wish to continue?",
            "Continue": "Continue",
            "Destination Wallet": "Destination Wallet",
            "tax": "fee",
            "Attention": "Attention",
            "Transaction in progress, wait for confirmation in history.": "Transaction in progress, wait for confirmation in history.",
            "Insert valid phone": "Insert a valid phone",
            "Sell": "Sell",
            "Buy": "Buy",
            "Documents": "Documents",
            "Select the document type": "Select the document type",
            'Personal_Documents': "PERSONAL DOCUMENTS",
            'Dependent_Documents': "DEPENDENT DOCUMENTS",
            'Send date': "Send date",
            'Document Type': 'Document Type',
            'Upload documents': 'Upload documents',
            'Upload document': 'Upload document',
            'Search File': 'Search File',
            'Document sent': 'Document sent',
            'No records found': 'No records found',
            'Select the file on your computer': 'Select the file on your computer',
            'Maximum file size': 'Maximum file size:',
            'Change Image': 'Change Image',
            'Data changed': 'Data changed',
            'Enter phone number': 'Enter phone number',
            'To complete a transaction, you get your "Google Authenticator" activated.': 'To complete a transaction, you activate your "Google Authenticator".',
            'Do you want to continue?': 'Do you want to continue?',
            'Your Bitcoin wallet': 'Your Bitcoin wallet',
            "Don't have a wallet": "Don't have a wallet",
            "Generate Wallet": "Generate Wallet",
            "Successfully created wallet!": "Successfully created wallet!",
            "No records found.": "No records found.",
            "General Data": "General Data",
            "Generate PDF": "Generate PDF",
            "Sign": "Sign",
            "Personal data": "Personal Data",
            "Zip Code": "Zip Code",
            "Street": "Street",
            "Number": "Number",
            "Neighborhood": "Neighborhood",
            "Complement": "Complement",
            "City": "City",
            "State": "State",
            "Application_Contratos": "Contracts",
            "Application_Support": "Support",
            "Application_AbrirChamado": "Open Ticket",
            "Application_Usuario": "User",
            "Application_Data": "Date",
            "Application_Assinatura": "Signature",
            "Application_Assunto": "Subject/Issue",
            "Application_Departamento": "Department",
            "Application_Prioridade": "Priority",
            "Application_Financeiro": "Financial",
            "Application_Baixa": "Low",
            "Send_Request_Support": "Send Support Request",
            "New_Support_Ticket": "Create new Support Ticket",
            "Application_StartMarket": "Set Up Market",
            "Contract value": "Contract Value",
            "Contract_N": "Contract No.",
            "Contract number": "Contract number",
            "Incorrect_Data": "Incorrect Data",
            "Mandatory_Assignment": "Mandatory Assignment",
            "Congratulations": "CONGRATULATIONS",
            "Start Date": "Start Date",
            "Final Date": "Final Date",
            "Type of contract": "Type of contract",
            "Application_Seuscontratos": "Your contracts",
            "Country": "Country",
            "State_issuance": "State of Issuance",
            "Title_Page_Policy_And_Privacy": "Privacy and Cookies Policy of Infinity Capital Global",
            "Last_Update": "Last Update: ",
            "Thankyou_Choosing_Infinity_Capital_Global": "Thank you for choosing Infinity Capital Global as your financial partner. We value your trust and commit to protecting your privacy and ensuring the security of your personal information. This Privacy and Cookies Policy is intended to clearly inform you how we collect, use, share, and protect your personal data.",
            "Consent": "Consent",
            "By_Using_Our_Services": "By using our services, you agree to the collection and use of your personal information in accordance with this Privacy and Cookies Policy. If you do not agree to the terms of this policy, we recommend that you do not continue to use our website and services.",
            "About_The_Company": "About the company",
            "Infinity_Capital_Global_Is_A_Financial_Platform": "Infinity Capital Global is a financial platform focused on facilitating access to the Cryptocurrency and Stock markets worldwide. Our goal is to provide a robust and user-friendly experience, offering financial services, mentorship, and tools to simplify the daily operations of our clients.",
            "Data_Collect": "Data collection",
            "In_Order_To_Provide": "In order to provide personalized financial services and ensure the security of our clients, we may collect the following personal information: Identification information: Full name, identification document (CPF, RG, or another legally valid document), date of birth, and nationality. Contact information: Residential address, email address, phone number, and other contact information. Financial information: Bank account number, transaction information, and payment history. Investment information: Data on investments, portfolio, and investment preferences. Login information: Username, password, and other information necessary for authentication on our platform.",
            "Purposes_Data_Processing": "Purposes of Data Processing",
            "The_Personal_Data_Collected": "The personal data collected are used for the following purposes: Provide financial services requested by the client, including executing transactions and investments. Comply with legal, regulatory, and contractual obligations applicable to financial services. Personalize our communication and provide updates on new products, special offers, events, and relevant information related to the services you use. Improve the quality of our services and ensure the security of our clients and our platform against fraudulent or suspicious activities.",
            "Data_Sharing": "Data Sharing",
            "Infinity_Capital_Global_Will_Not_Share": "Infinity Capital Global will not share, sell, or transfer your personal data to third parties, except as described in this policy or with your express consent for specific purposes. We may share your personal data with: Third-party service providers: We may use third-party companies to provide services on our behalf, such as payment processing, data analysis, customer support, among others. These companies have limited access to your information and are prohibited from using it for any other purpose than providing the contracted services. Government authorities: In compliance with legal and regulatory obligations, we may be required to share your information with government authorities or regulatory bodies.",
            "Data_Security": "Data Security",
            "We_Adopt_Technical": "We adopt technical, organizational, and security measures to protect your personal data from unauthorized access, loss, alteration, or disclosure. Our employees are trained to treat your information confidentially and are subject to confidentiality obligations.",
            "Cookies_Tracking_Technologies": "Cookies and Tracking Technologies",
            "Our_Website_Uses_Cookies": "Our website uses cookies and tracking technologies to enhance your browsing experience and collect information about website usage. These cookies are used to remember your preferences, analyze trends and browsing patterns, personalize content, and display relevant ads. You can manage your cookie preferences in your browser settings. By continuing to use our website without modifying your cookie settings, you agree to the use of cookies.",
            "Your_Rights": "Your Rights",
            "You_Have_Rights": "You have rights regarding your personal data: Access: You can request information about the data we hold about you. Rectification: If your information is incorrect or outdated, you can request corrections. Deletion: You have the right to request the deletion of your data, unless there are legal or contractual obligations preventing such deletion. Portability: Your data may be transferred to another company, if technically feasible. Opposition: In certain circumstances, you can object to the processing of your personal data.",
            "Minors": "Minors",
            "Our_Services_Are_Not_Intended": "Our services are not intended for individuals under 18 years of age. If we become aware that we have collected personal data from a minor, we will take measures to delete such data as quickly as possible.",
            "Application_O_Seriousness": "Application of Seriousness and Customer First",
            "At_Infinity_Capital_Global": "At Infinity Capital Global, we apply seriousness in everything we do. The customer always comes first, and our commitment is to provide high-quality financial services, support, mentorship, and tools to simplify your financial experience.",
            "Changes_To_The_Privacy_Policy": "Changes to the Privacy Policy",
            "We_Reserve_The_Right_To_Update": "We reserve the right to periodically update this Privacy Policy to reflect changes in our privacy practices. We recommend that you review this policy regularly to stay informed of any updates.",
            "Contact_And_Support": "Contact and Support",
            "In_Case_Of_Douts": "In case of questions, uncertainties, or any other needs, our clients can contact us",
            "application_textrodape": "Infinity Capital will provide the Client with commercially reasonable support and pertinent information to enable it to satisfy the requirements of all applicable regulatory/licensing agencies and associated statutes, including FSA Security (Seychelles), 8716294-1 SEC 108/13"
        }
    },
    es: {
        translation: {
            "Application_Contato": "Contacto",
            "Application_NaoMostrarSaldoZero": "Ocultar saldo cero",
            "Application_TotalExchange": "Activos en la bolsa",
            "Application_SaldoAtivos": "Activos",
            "Application_Todos": "Todo",
            "Application_FalarComSeugerente": "Hablar con el Gerente",
            "Application_Configurations": "Configuraciones de cuenta",
            "Application_Referal": "Programa de recomendación",
            "Application_TextoReferal": "Invita a amigos y gana hasta 100 USDT y un 5% de comisión",
            "Application_Inbox": "Bandeja de entrada",
            "Application_TextoInbox": "Revise los mensajes enviados por nuestro equipo",
            "Application_TextoNotifications": "Vea todas sus notificaciones",
            "Application_MaisOpcoes": "+ Opciones",
            "Application_TextoAbrirChamado": "Abra una solicitud de soporte para nuestro equipo y obtenga una respuesta rápida",
            "Application_TextoSuporte": "Vea todas sus solicitudes de ayuda",
            "Application_TextoSaqueFiat": "Realice un retiro rápido de sus fondos a su cuenta bancaria utilizando PIX, TED o DOC",
            "Application_TextoEmprestimo": "Solicite un préstamo utilizando su margen disponible y pague de manera segura y tranquila",
            "Application_TextoSaqueCrypto": "Realice un retiro rápido de sus fondos a su billetera de criptomonedas",
            "Application_DepositCrypto": "Depositar Criptomonedas (USDT, BTC) y mucho más",
            "Application_TextoDepositar": "Realice un depósito en fiat de manera sencilla y segura",
            "Application_TextoDepositarCrypto": "Realice un depósito en criptomonedas de manera sencilla y segura",
            "Application_TextoMercados": "Negocie de manera más inteligente con nuestras diversas estrategias automatizadas, fáciles, rápidas y confiables",
            "Application_TextStocks": "Resumen del mercado de acciones con la ayuda de nuestra IA",
            "Application_TextCrypto": "Resumen del mercado de criptomonedas y sus activos",
            "Application_SelectLanguage": "Seleccione su idioma",
            "Application_Cadastro": "Crear cuenta",
            "Application_InvoiceNumber": "Factura N",
            "Application_InvoiceDate": "Fecha de la factura",
            "Application_Nivel": "Nivel de soporte",
            "Application_Notifications": "Notificaciones",
            "Applications_TradersMercado": "Traders de mercado",
            "Application_Qtd": "Ctd",
            "Application_Total": "Total",
            "Application_TodosTickes": "Todos los tickets",
            "Application_Deposito": "Depositar",
            "Application_Saque": "Retirar",
            "Application_ValorContrato": "Valor del contrato",
            "Application_AguardandoEmprestimo": "En revisión",
            "Application_Aprovado": "Aprobado",
            "Application_Liberado": "Liberado",
            "Application_Cancelado": "Cancelado",
            "Application_NaoAprovado": "No Aprobado",
            "Application_MsgEmprestimo": "Su margen para solicitar un préstamo es de hasta {{margemEmprestimo}} USD y puede realizar el pago en un máximo de {{qtdParcel}}x de {{ValorParcel}}",
            "Application_Emprestimos": "Préstamos",
            "Application_SolicitarEmprestimo": "Solicitar Préstamo",
            "Application_MargemEmprestimo": "Margen para préstamo",
            "Application_Score": "Puntuación",
            'Application_DadosInexistentes': "Datos Inexistentes o no existen contratos para usted",
            "Application_ContratoN": "Contrato Nº",
            "Application_QtdParcelas": "Ctd Parcelas",
            "Application_Opcoes": "Opciones",
            "Application_informacao": "Información",
            "Application_Contrato": "CONTRATO N",
            "Application_DetallesPagamentos": "Detalles de pago de cuotas del contrato N",
            "Application_Shares": "ACCIONES",
            "Application_VolumeStock": "VOLUMEN",
            "Application_RSIStock": "RSI",
            "Application_Posicao": "POSICIÓN",
            "Application_MediasMoveis": "MEDIAS MÓVILES",
            "Application_CodBank": "CÓD. BANCO",
            "Application_PassCod": "Cód. de Contraseña",
            "Application_CheckOperar": "Operar con dinámica de mercado",
            "Application_Comprada": "COMPRADA",
            "Application_Vendedora": "VENDEDORA",
            "Application_CFDS": "CFDS",
            "Application_IniciarOperacao": "INICIAR OPERACIÓN",
            "Application_ValorTotal": "Valor de compra",
            "Application_ConfirmarOperarStock": "OPERAR CON DINÁMICA DE MERCADO",
            "Application_Capital": "SALDO",
            "Application_SaldoStock": "SALDO",
            "Application_SetupStocks": "Configure su Software",
            "Application_DataDeNascimento": "Fecha de nacimiento",
            "Application_StartMarketStocks": "Configurar Software",
            "No_Response_Found": "Sin respuesta en este momento, esperando a los agentes",
            "Send_Reply_Support": "Enviar respuesta",
            "Send_Reply": "Enviando respuesta",
            "Application_Responder": "Responder al ticket",
            "Assunto_Required_Error": "Campo Asunto necesario",
            "Departamento_Required_Error": "Campo Departamento necesario",
            "Prioridade_Required_Error": "Campo Prioridad necesario",
            "Conteudo_Required_Error": "Campo Contenido necesario",
            "Application_Anexar_Arquivo": "Adjuntar archivo",
            "File_Required_Error": "Campo archivo necesario",
            "Select_Prioridade": "Seleccione la prioridad",
            "Select_Departamento": "Seleccione el departamento",
            "Application_Supporte": "Soporte",
            "Sending_Request": "Enviando solicitud",
            "Application_msgStopRobo2": "Puede detener su robot de manera segura y sin pérdida de capital",
            "Application_DigiteValor": "Ingrese el valor que desea convertir en saldo",
            "Application_msgSaque": "¿Confirma la retirada del valor de {{valor}} de su capital disponible?",
            "Application_msgSaqueConfirmado": "El valor de {{valor}} ya está disponible para su retiro en el sistema. ¡Gracias!",
            "Application_MsgSaqueNaoHabilitado": "Robot en funcionamiento, póngase en contacto con su analista, ya que las ganancias pueden estar siendo utilizadas como margen de operación",
            "Application_BtnSacarDisponivel": "Habilitar para retiro",
            "Application_SelecioneCarteira": "Seleccione la billetera",
            "Application_ContaAwaiterificada": "Documentos enviados, esperando verificación del equipo",
            "Application_msgDepositarCrip": "Para realizar un depósito en criptomoneda, indique el tipo de moneda que desea",
            "Application_Deposit": "Depósito",
            "Application_euquerodepositar": "Quiero depositar",
            "Application_viaRede": "a través de la red",
            "Application_SelecionaUmaRede": "Selecciona una red",
            "Application_Cryptos": "Criptomonedas",
            "Application_ValorDeposito": "Ingrese el valor del depósito",
            "Application_ValorMinimo": "Valor mínimo de depósito",
            "Application_Confirmacoes": "Confirmaciones en la red",
            "Application_ConfirmarDeposito": "Confirmar depósito",
            "Application_Stock": "Acciones",
            "Application_Arbitragem": "Arbitraje",
            "Application_redeChain": "Seleccionar red",
            "Application_confirm": "Confirmar",
            "Application_cancel": "Cancelar",
            "Application_stop": "Detener",
            "Application_Atencion": "Atención",
            "Application_msgStopRobo": "¿Realmente desea detener este robot? Detenerlo puede provocar pérdidas en el mercado y en sus acciones",
            "Application_textMissao": "Infinity Capital, fundada en 2015 por visionarios empresarios, tiene como misión liderar la transformación de los mercados financieros a nivel mundial. Comenzando con la minería de oro, combinamos tradición e innovación para alcanzar el éxito y el reconocimiento. Con agilidad, abrazamos las criptomonedas y la tecnología blockchain, expandiendo nuestra influencia. A través de la inteligencia artificial, revolucionamos nuestros procesos y transacciones, manteniéndonos ágiles ante los cambios. Nuestra plataforma de vanguardia atrae a clientes diversos y nuestra sede en Londres simboliza nuestra presencia global. Nos impulsa la búsqueda incesante de la excelencia, uniendo tradición e innovación para definir el futuro financiero global.",
            "Application_textVisao": "Nuestra visión es liderar la transformación del panorama financiero mundial. Combinando tradición e innovación, aspiramos a redefinir estándares en minería, criptomonedas e inteligencia artificial. Queremos ser un centro de colaboración, facilitando decisiones ágiles a través de la inteligencia artificial y estableciendo una presencia global impactante. Nuestra cultura de excelencia e innovación está impulsada por la pasión por desafiar límites y crear un futuro financiero ilimitado.",
            "Application_QuemSomos_01": "El 1 de noviembre de 1999, un grupo de visionarios fundó Infinity Capital, una empresa innovadora que inició sus operaciones con un enfoque en la minería de oro y la participación en operaciones en el mercado financiero. Con un enfoque único y tecnológicamente avanzado, la empresa destacó rápidamente en estos sectores, utilizando métodos modernos y eficientes para extraer el precioso metal y ganar credibilidad, brindando resultados a sus inversionistas.",
            "Application_QuemSomos_02": "El 22 de noviembre de 2005, la empresa fue licenciada por el organismo regulador del Reino Unido, la FCA, ampliando sus horizontes para abarcar capitales institucionales de importantes proveedores de liquidez, posibilitando también la colaboración con bancos a través de acciones, materias primas e índices.",
            "Application_QuemSomos_03": "Sin embargo, a medida que el mundo presenciaba el auge de las criptomonedas y la tecnología blockchain, Infinity Capital vio una oportunidad de expandir y diversificar sus negocios. En un movimiento estratégico en consonancia con esta tendencia, la empresa fue incorporada al grupo JP Morgan, una de las instituciones financieras más influyentes y respetadas a nivel mundial.",
            "Application_QuemSomos_04": "Tomando el papel de director de Infinity Capital, junto con David Brown, estaba Denis Costa, un líder experimentado con una visión orientada a la innovación y la tecnología. Bajo su dirección y con la amplia experiencia de JP Morgan, la empresa dio un salto significativo en su evolución.",
            "Application_QuemSomos_05": "A partir de ese momento, Infinity Capital dirigió sus recursos al desarrollo de robots impulsados por inteligencia artificial, con miras al futuro. Estos robots estaban programados para analizar datos de mercado, identificar tendencias y ejecutar operaciones de criptomonedas de manera automatizada y precisa. Este enfoque pionero rápidamente se convirtió en una de las marcas registradas de Infinity Capital, estableciéndola como líder en el campo de la negociación algorítmica de criptomonedas.",
            "Application_QuemSomos_06": "A medida que los robots de Infinity Capital continuaron entregando resultados excepcionales en medio de la volatilidad del mercado, la empresa consolidó su posición como una de las mayores plataformas de intercambio en el mercado internacional. Su plataforma de negociación se hizo conocida por su velocidad, seguridad y eficiencia, atrayendo a traders e inversionistas de todo el mundo.",
            "Application_QuemSomos_07": "Para sostener su crecimiento y establecerse como un nombre icónico en la industria, Infinity Capital eligió la impresionante Torre Heron, ubicada en la dirección 110 Bishopsgate, Londres EC2N 4AY, Reino Unido, como su sucursal. Esta ubicación estratégica no solo refleja la posición destacada de la empresa, sino que también simboliza su visión de elevar el estándar para los servicios financieros basados en criptomonedas.",
            "Application_QuemSomos_08": "Con el tiempo, Infinity Capital continuó innovando y ampliando sus horizontes, colaborando con expertos en IA y blockchain, manteniéndose a la vanguardia de la tecnología financiera. Su historia está marcada por desafíos superados, logros notables y un compromiso inquebrantable con la excelencia. Así, Infinity Capital continúa dando forma al futuro de las finanzas, explorando nuevas fronteras y allanando el camino hacia una era verdaderamente revolucionaria de negociación e inversión.",
            "Application_QuemSomos_08": "",
            "Application_QuemSomos_09": "",
            "Application_QuemSomos_10": "",
            "Application_textValores1": "Innovación Sostenible: Creamos soluciones duraderas a través del cambio y la tecnología.",
            "Application_textValores2": "Integridad Inquebrantable: Mantenemos altos estándares éticos para la confianza y la transparencia.",
            "Application_textValores3": "Colaboración Empoderadora: Valoramos ideas diversas y crecemos juntos.",
            "Application_textValores4": "Adaptación Ágil: Respuesta rápida y efectiva a los cambios del mercado.",
            "Application_textValores5": "Excelencia Continua: Búsqueda incesante de la mejora en todos los aspectos.",
            "Application_textValores6": "Responsabilidad Social y Ambiental: Contribuimos positivamente a las comunidades y al medio ambiente.",
            "Application_textValores7": "Pasión por el Crecimiento: La pasión por aprender nos mantiene innovadores y en crecimiento.",
            "Application_Text1": "un equipo esperándote. ",
            "Application_Text2": "Una marca conocida a nivel mundial",
            "Application_ConteComEquipe": "Cuenta con nuestro equipo para administrar tus activos e inversiones",
            "Application_RegistrarAgora": "Registrarse",
            "Application_Jatenhoconta": "Ya tengo una cuenta",
            "Application_QuemSomos": "Quiénes Somos",
            "Join": "Únete",
            "Application_TxtQuemSomos": "Una plataforma centrada en facilitar el acceso a los mercados de criptomonedas y acciones en todo el mundo.",
            "Application_Txt2QuemSomos": "Con un enfoque principal en la inclusión y en mejorar el acceso de todos al mercado de criptomonedas. Infinity Global ofrece soluciones que van más allá de las herramientas tradicionales. Estamos preparados para la era de la inclusión financiera y, desde lo más básico hasta lo más avanzado, estamos listos para abrir las puertas de este mercado para usted.",
            "Application_Servicos": "Servicios",
            "Application_Market": "Precios de los Mercados",
            "Application_agentes": "Agentes",
            "txt_agentes": "Agentes distribuidos en todo el mundo para brindarle un servicio platinum.",
            "Application_plataforma": "Nuestra Plataforma",
            "Application_Txtplataforma": "Una plataforma robusta y sencilla para que disfrute de lo mejor y más seguro.",
            "Application_txtApi": "La API está diseñada para proporcionar una forma fácil y eficiente de integrar su aplicación de trading en nuestra plataforma.",
            "Description_Bottom": "Infinity Capital es la marca registrada de sitios web y plataformas digitales operadas por INFINITY CAPITAL MANAGEMENT LTD. y sus empresas separadas pero afiliadas, Infinity Capital Securities LLC, Infinity Capital, Infinity Capital Digital Assets LLC, Infinity Capital Cash LLC. Heron Tower, 110 Bishopsgate, Londres EC2N 4AY, Reino Unido",
            "Application_Mercados": "Mercados",
            "Application_SetupMercados": "Configurar su robot",
            "Button_Success_Confirure_Robot": "Iniciar proceso",
            "Message_Select_Option": "Seleccione una opción",
            "Unavailable_Balance": "Saldo no disponible",
            "Message_Invalide_Token": "Token inválido",
            "Application_Pais": "País: ",
            "Application_SejaBemVindo": "Bienvenido",
            "Application_ContaSemVerificacao": "Su cuenta aún no ha sido verificada. Su acceso se limita al nivel básico. Para normalizar su acceso, seleccione la pestaña de gestión y actualice sus datos para completar la verificación de su cuenta.",
            "Application_CadastradoEm": "Registrado en: ",
            "Application_exchangePro": "Exchange",
            "Application_Modelo": "Opciones",
            "Application_Perfil": "Configuración",
            "Application_Agressivo": "Agresivo",
            "Application_Moderador": "Moderado",
            "Application_Convservador": "Conservador",
            "Application_Selecionar": "Seleccionar",
            "Application_OrdemPendente": "Operaciones Inmediatas",
            "Application_ExecucaoInstantanea": "Analizar y Operar",
            "Application_Saldo": "Capital",
            "Application_TipoStop": "Tipo de parada",
            "Application_Valor": "Valor",
            "Application_Lucros": "Ganancia Total",
            "Application_Disponivel": "Ganancia restante",
            "Application_Porcentagem": "Porcentaje %",
            "Application_StopWin": "Profit",
            "Application_StopLoss": "Stop Loss",
            "Application_BBS": "EMA",
            "Application_RSI": "BOLLINGER",
            "Application_MACD": "FRACTAL",
            "Application_Volume": "MARGEN OP.",
            "Application_DigiteToken": "Ingrese el Token enviado por su gerente.",
            "Withdraw Fiat History": "Historial de retiros de dinero Fiat",
            "Submit": "Enviar",
            "Application_EnviarDocumentos": "Subir documentos",
            "Application_ContaVerificada": "Cuenta verificada",
            "Apllication_verificacaoConta": "Verificación de cuenta",
            "Application_NaoAssinado": "No firmado",
            "Application_Assinado": "Firmado",
            "Application_Assinado_Sucesso": "FIRMADO CON ÉXITO",
            "Application_Tickets": "Sus Tickets",
            "Application_Status": "Estado",
            "Application_Acoes": "Acciones",
            "Application_Aberto": "Abierto",
            "Application_emAndamento": "En progreso",
            "Application_AguardandoStaff": "Esperando al personal",
            "Application_AguardandoMembro": "Esperando al miembro",
            "Application_Fechado": "Cerrado",
            "Application_Abrir": "Abrir",
            "Application_Fechar": "Cerrar",
            "Application_USD": "USD",
            "Application_CopiarPix": "Copiar Código Pix",
            "Application_Administracao": "Administración",
            "Application_Compilance": "Cumplimiento",
            "Application_Brokers": "Brokers y Agentes",
            "Application_Media": "Media",
            "Application_Alta": "Alto",
            "Application_Informaciones": "Información de la Solicitud",
            "Application_TicketNumber": "Ticket N",
            "Data_Ticket": "Datos del TICKET",
            "Application_Enviado": "Enviado en",
            "Application_Cliente": "Cliente",
            "Application_VoceEstaSendo": "Está siendo referido por: ",
            "Application_Naoexiste": "Este usuario no existe en nuestra base de datos, corrija los datos",
            "Application_WalletUSD": "Su billetera en USD",
            "Application_OperacaoStop": "Su Robot está en funcionamiento. Póngase en contacto con su analista",
            "Application_AtivoInvestments": "Inversión Activa",
            "Application_Ordens": "Órdenes",
            "Application_Investimento": "Inversión: ",
            "Application_Available": "Ganancia restante",
            "Application_Retorno": "Ganancia Total: ",
            "Application_Robo": "Mis Robots",
            "Application_Hash": "Hash",
            "Application_ContractID": "Contrato Ref",
            "Application_Visualizar": "Visualizar",
            "Application_RelatorioGanhos": "Informe de Ganancias",
            "Application_Ativos": "Activo",
            "Application_Aguardando": "En espera",
            "Application_Finalizado": "Finalizado",
            "Application_ContratoAtual": "Contrato Hash",
            "Application_HashTransaction": "Hash de Transacción",
            "Application_Ganhos": "Ganancias",
            "Application_priceBuy": "Price Buy",
            "Application_priceSell": "Price Sell",
            "Application_Tipo": "Tipo",
            "Application_Gain": "Ganancia",
            "Application_Loss": "Pérdida",
            "Application_Banner_Legenda1": "Leyenda del banner 01",
            "What_Shipping": "¿Qué métodos de envío están disponibles?",
            "How_Long": "¿Cuánto tiempo llevará recibir mi paquete?",
            "How_Do_I_Track": "¿Cómo hago el seguimiento de mi pedido?",
            "Sign up": "Registrarse",
            "Create_Account": "Crear una cuenta",
            "Join_Platform": "Únase a nuestra plataforma",
            "Create your account and start investing": "Cree su cuenta y comience a invertir",
            "Home": "Inicio",
            "Assets": "Activos",
            "Guide": "Guía",
            "Language": "Idioma",
            "News": "Noticias",
            "Trade with ": "Operar con ",
            "Buys and sell criptocurrencies": "Comprar y vender criptomonedas",
            "Fast and safe": "Rápido y seguro",
            "Be part of the revolution": "Sea parte de la revolución",
            "English": "Inglés",
            "Portuguese": "Portugués",
            "Spanish": "Español",
            "Your wallet": "Su billetera",
            "Deposit_Request": "Solicitud de depósito",
            "Index Price": "Precio del Índice",
            "24h Change": "Cambio en 24h",
            "1h Change": "Cambio en 1h",
            "Market Cap": "Capitalización de mercado",
            "24h Volume": "Volumen en 24h",
            "Balance": "Saldo",
            "Size": 'Cantidad',
            "Final Value": "Valor Final",
            "Average price": "Precio Promedio",
            "Time": "Tiempo",
            "Description": "Descripción",
            "Order History": "Historial de Órdenes",
            "Symbol": "Símbolo",
            "Price": "Precio",
            "Last Modify": "Última Modificación",
            "Market": "Mercado",
            "Balance in wallet": "Saldo en billetera",
            "Buy Now": "Comprar Ahora",
            "Sell Now": "Vender Ahora",
            "Pairs": "Pares",
            "Last Price": "Último Precio",
            "Change": "Cambio",
            "Trade History": "Historial de Operaciones",
            "Order not placed": "Orden no realizada",
            "Order placed": "Orden realizada",
            "You sold": "Usted vendió",
            "You bought": "Usted compró",
            "Order value must be greater than 0": "El valor de la orden debe ser mayor que 0",
            "Insufficient funds": "Fondos insuficientes",
            "Hello": "Hola",
            "Trades made": "Operaciones realizadas",
            "Balance available": "Saldo disponible",
            "Deposit": "Depósito",
            "Account": "Perfil",
            "Logout": "Cerrar sesión",
            "Pay to receive your funds": "Realice el pago para recibir sus fondos",
            "Value": "Valor",
            "Type": "Tipo",
            "Code": "Código",
            "Pending": "Pendiente",
            "Paid": "Pagado",
            "Expired": "Expirado",
            "Canceled": "Cancelado",
            "Reversed": "Revertido",
            "Deposit not placed": "Depósito no realizado",
            "Deposit value must be greater than 0": "El valor del depósito debe ser mayor que 0",
            "Confirmation URL": "URL de confirmación",
            "Deposit fiat": "Depositar fiat",
            "Deposit crypto": "Depositar cripto",
            "Bankslip": "Boleto",
            "Credit Card": "Tarjeta de crédito",
            "Access the bankslip": "Acceder al boleto",
            "Access QR code on PicPay": "Acceder al código QR en PicPay",
            "Select your bank": "Seleccione su banco",
            "Your cryptocurrency wallet": "Su billetera de criptomonedas",
            "Show": "Mostrar",
            "Verified on blockchain": "Verificado en la cadena de bloques",
            "Hide": "Ocultar",
            "Use this wallet to receive": "Use esta billetera para recibir",
            "Your Ethereum wallet": "Su billetera Ethereum",
            "This value was added to your founds": "Este valor fue añadido a sus fondos",
            "Withdraw crypto": "Retirar cripto",
            "Withdraw fiat": "Retirar REAL",
            "Address": "Dirección",
            "Copy the address of your external wallet": "Copie la dirección de su billetera externa",
            "Withdraw": "Retirar",
            "Select your token": "Seleccione su token",
            "An error ocurred on your deposit": "Se produjo un error en su depósito",
            "Withdraw value must be greater than 0 and enter a valid address": "El valor del retiro debe ser mayor que 0 y debe ingresar una dirección válida",
            "Withdraw History": "Historial de retiros",
            "Receiver address": "Dirección del destinatario",
            "Eth withdraws are not working at the moment": "Los retiros de Eth no están funcionando en este momento",
            "index join": "Únase a la ",
            "index subtitle": "Compre, venda e invierta en criptomonedas, tokens y activos criptográficos en el primer Exchange del mundo que posee billeteras y monedas con responsabilidad y propósito social. Además de ser el único Exchange con su propio ecosistema que potencia las inversiones de todos sus miembros.",
            "index steps": "Toda gran jornada comienza con los primeros 3 pasos, ¿verdad? ¡Únete a nosotros!",
            "index createAc": "Crea tu cuenta, la facilidad es nuestro objetivo",
            "Enjoy": "Disfruta",
            "Deposit your founds": "Deposita tus fondos",
            "Start to buy and sell": "Empieza a comprar y vender",
            "index thirdTitle": "Personaliza tu paraguas para proteger tus inversiones",
            "index thirdIndexSubtitle": "ID UMBRELLA SOCIAL ID tiene una variedad de recursos y herramientas tecnológicas que se aplican a través de la organización del conocimiento, compartiéndolo para generar la sabiduría colectiva que todos esperamos. A través de este concepto, podemos ayudar a los principiantes y colaborar con los expertos para perfeccionarse aún más.",
            "Manage your portfolio": "Administra tu cartera",
            "manage text": "Invierte, compra y vende activos digitales y haz un seguimiento de ellos en un solo lugar",
            "Recurring buys": "Compras recurrentes",
            "recurring text": "Invierte en la economía disruptiva de manera segura y lenta, mantente al tanto de las novedades y sugerencias a través de este portal",
            "Vault protection": "Protección de bóveda",
            "vault text": "Para mayor seguridad, almacena tus inversiones en tu cartera personal y aprovecha la agilidad de tener tus recursos disponibles las 24 horas del día, en cualquier lugar del mundo.",
            "Mobile": "Móvil",
            "mobile text": "Sigue de cerca los mercados a través de las aplicaciones de ID SOCIAL UMBRELLA EXCHANGE. Sistema del grupo ID IDENTIFICATION",
            "Deposit History": "Historial de depósitos",
            "Password": "Contraseña",
            "Sign in": "Iniciar sesión",
            "Forgot Password?": "¿Olvidaste tu contraseña?",
            "Remember me": "Recuérdame",
            "Don't have an account?": "¿No tienes una cuenta?",
            "liquidity text": "Además de acceder a los recursos de tus inversiones en cualquier parte del mundo, ya estás colaborando para un mundo mejor. En este mundo, los recursos están destinados a ser utilizados por las personas. En el modelo que estamos desmantelando, las personas están esclavizadas por el dinero. ¿Qué tiene más valor para ti, el dinero o el ser humano?",
            "index trust": "Confiamos en nuestros socios, inversores y en nuestros valores",
            "trust text": "Nacimos con más de 50,000 miembros inversores distribuidos en más de 160 países, siendo el primer Exchange del mundo en el que se requiere que cada miembro sea partidario del pacto de tratados de derechos humanos indicado por la ONU. En palabras de nuestro fundador, Henrique Barack Obama, doctor en Economía, Finanzas y Negocios de Criptomonedas: 'Tenemos la mayor oportunidad en la historia de reducir la pobreza y apoyar causas sociales y humanitarias utilizando las criptomonedas y cumplir el verdadero propósito de la tecnología blockchain'.",
            "index confident": "La plataforma más disruptiva y descentralizada del mundo",
            "confident text": "Aquí tienes algunas razones para elegir e indicar ID SOCIAL UMBRELLA EXCHANGE",
            "Ideals": "Ideales",
            "Legislation": "Legislación",
            "Obligations": "Obligaciones",
            "Practices": "Buenas prácticas socioambientales",
            "legis text": "No nos limitamos a cumplir las leyes ni a reforzar la supervisión del ecosistema del que formamos parte",
            "obri text": "Sabemos que no somos simplemente una plataforma de alto valor agregado o simplemente una Exchange innovadora, disruptiva e inclusiva. Nacimos para ser la diferencia que esperábamos ver.",
            "prat text": "Deseamos actuar en apoyo a la eficiencia energética, la eficiencia en el uso de los recursos naturales, minerales, hídricos, en la preparación para abordar el cambio climático, en la calidad de la gestión de residuos y en el desarrollo humano, entre otros temas.",
            "index idiom": "Elige tu idioma",
            "idiom text": "Nuestra propuesta es dar la bienvenida a personas de todo el mundo que deseen el cambio, por lo que nuestro equipo trabaja para ofrecer una plataforma con múltiples idiomas que permita el acceso global",
            "liquidity": "Liquidez y contribución a un mundo mejor",
            "Login Error": "Error de inicio de sesión",
            "Internal Transfer": "Transferencia interna",
            "Change withdraw method": "Cambiar el método de retiro",
            "Insert your withdraw info": "Ingresa tu información de retiro",
            "Pix Key": "Clave Pix",
            "Withdraw now": "Retirar ahora",
            "Account number": "Número de cuenta",
            "Agency": "Agencia",
            "Digit": "Dígito",
            "Operation": "Operación",
            "Bank": "Banco",
            "Waiting Approval": "Esperando aprobación",
            "Approved": "Aprobado",
            "Refused": "Rechazado",
            "Of": "de",
            "Transfer": "Transferir",
            "Transfer now": "Transferir ahora",
            "Transfer History": "Historial de transferencias",
            "Deposit now": "Depositar ahora",
            "Please double-check to make sure": "Por favor, verifica dos veces para asegurarte",
            "Total in Dollar": "Total en dólares",
            "Total in Reais": "Total en reales",
            "Nationality": "Nacionalidad",
            "Document": "CPF/CNPJ",
            "Telephone": "Teléfono",
            "Insert Email": "Ingresar el correo electrónico",
            "Already have an account?": "¿Ya tienes una cuenta?",
            "Insert mobile phone": "Ingresar teléfono móvil",
            "Insert phone": "Ingresar teléfono",
            "Insert Document": "Ingresar CPF o CNPJ",
            "Select your document type": "Selecciona tu tipo de documento",
            "Select Your Document": "Selecciona tu documento",
            "mobile must be unique": "El teléfono móvil ya está registrado",
            "Name": "Nombre",
            "Sign up your account": "Registra tu cuenta",
            "Username": "Nombre de usuario",
            "Password confirm": "Confirmación de contraseña",
            "Next": "Siguiente",
            "Cancel": "Cancelar",
            "Confirm": "Confirmar",
            "External wallet": "Billetera externa",
            "Phone": "Teléfono",
            "Recipient user phone": "Teléfono del usuario destinatario",
            "Select your type Transfer": "Selecciona tu tipo de transferencia",
            "Recipient user wallet address": "Dirección de la billetera del usuario destinatario",
            "Recipient user email": "Correo electrónico del usuario destinatario",
            "Recipient username": "Nombre de usuario del destinatario",
            "to": "para",
            "Do you want to transfer": "¿Quieres transferir",
            "Details": "Detalles",
            "Check details": "Verificar detalles",
            "Payment details": "Detalles del pago",
            "My data": "Mis datos",
            "My Wallet": "Mi billetera",
            "My documents": "Mis documentos",
            "Bank data": "Datos bancarios",
            "Security": "Seguridad",
            "Deposit Funds": "Depositar fondos",
            "Payment method": "Método de pago",
            "Status": "Estado",
            "Date": "Fecha",
            "Bank Data Listing": "Listado de datos bancarios",
            "Add Bank Account": "Agregar cuenta bancaria",
            "Add Pix": "Agregar Pix",
            "Save modifications": "Guardar modificaciones",
            "Confirm new password": "Confirmar nueva contraseña",
            "New password": "Nueva contraseña",
            "Current password": "Contraseña actual",
            "First Name": "Primer nombre",
            "Second Name": "Segundo nombre",
            "RG Document": "Documento RG",
            "Issuing Body": "Órgano emisor",
            "Status of Issuance": "Estado de emisión",
            "Father": "Padre",
            "Mother": "Madre",
            "Type of account": "Tipo de cuenta",
            "Key Name": "Nombre de la clave",
            "Unregistered data": "Datos no registrados",
            "Holder name": "Nombre del titular",
            "Finish Registration": "Finalizar registro",
            "Type of key": "Tipo de clave",
            "Key": "Clave",
            "Random Key": "Clave aleatoria",
            "Payment data": "Datos del pago",
            "Enter your email": "Ingresa tu correo electrónico",
            "User not found": "Usuario no encontrado",
            "Transfer failed": "Transferencia fallida",
            "Unexpected error": "Error inesperado",
            "Enter the transfer amount": "Ingresa el monto de la transferencia",
            "Transfer type": "Tipo de transferencia",
            "Verify on blockchain": "Verificar en la cadena de bloques",
            "Savings Account": "Cuenta de ahorros",
            "Current Account": "Cuenta corriente",
            "Recipient user": "Usuario destinatario",
            "Sending user": "Usuario remitente",
            "Sender Transfer History": "Historial de transferencias del remitente",
            "Transfer Received History": "Historial de transferencias recibidas",
            "Remove": "Eliminar",
            "Activate": "Activar",
            "An error has occurred. Please try again.": "Ha ocurrido un error. Por favor, inténtalo de nuevo.",
            "Withdrawal must be greater than or equal to 1 Dollar": "El retiro debe ser mayor o igual a 1 dólar",
            "Make sure you have the": "Asegúrate de tener la",
            "application installed on your phone.": "aplicación instalada en tu teléfono antes de configurar esta opción de seguridad.",
            "Google Authenticator Enabled!": "¡Google Authenticator habilitado!",
            "SCAN THE QR CODE": "ESCANEA EL CÓDIGO QR",
            "Second Authentication Factor": "Segundo factor de autenticación",
            "2nd Authentication Factor": "2do factor de autenticación",
            "Change Password": "Cambiar contraseña",
            "Type the code": "Ingrese el código",
            "Wrong code, try again.": "Código incorrecto, inténtelo de nuevo.",
            "Error confirming second authentication factor.": "Error al confirmar el segundo factor de autenticación.",
            "Success": "Éxito",
            "Authenticator successfully removed.": "Autenticador eliminado con éxito.",
            "Enter the code sent to the email": "Ingrese el código enviado al correo electrónico:",
            "Confirm Email": "Confirmar correo electrónico",
            "Resend": "Reenviar",
            "Don't Received?": "¿No lo recibiste?",
            "Error resend email, try again.": "Error al reenviar el correo electrónico, inténtelo de nuevo.",
            "Email sent.": "Correo electrónico enviado.",
            "Error confirming email, please try again.": "Error al confirmar el correo electrónico, por favor inténtelo de nuevo.",
            "Value in": "Valor en",
            "Coin token": "Token de moneda",
            "Transfer date and time in dd/mm/yyyy": "Fecha y hora de transferencia en dd/mm/aaaa",
            "Received": "Recibido",
            "Sent": "Enviado",
            "Username already registered": "Nombre de usuario ya registrado.",
            "Document already registered": "Documento ya registrado.",
            "E-mail already registered": "Correo electrónico ya registrado.",
            "Error confirming registration, try again.": "Error al confirmar el registro, inténtelo de nuevo.",
            "Registration error": "Error de registro.",
            "Cell phone already registered": "Teléfono celular ya registrado.",
            "Password must be different from the current one.": "La contraseña debe ser diferente de la actual.",
            "Error changing password, please try later.": "Error al cambiar la contraseña, por favor inténtelo más tarde.",
            "Error changing password": "Error al cambiar la contraseña",
            "Incorrect current password.": "Contraseña actual incorrecta.",
            "Passwords do not match.": "Las contraseñas no coinciden.",
            "Password changed successfully.": "Contraseña cambiada con éxito.",
            "Enter password with 6 to 21 characters": "Ingrese una contraseña de 6 a 21 caracteres",
            "Enter password": "Ingrese una contraseña",
            "Enter new password and / or password confirmation": "Ingrese una nueva contraseña y/o confirmación de contraseña",
            "Request reset": "Solicitar restablecimiento",
            "Reset password": "Restablecer contraseña",
            "Back to login": "Volver al inicio de sesión",
            "Incorrect code.": "Código incorrecto.",
            "Incorrect document.": "Documento (CPF o CNPJ) incorrecto.",
            "Authentication completed successfully.": "Autenticación completada con éxito.",
            "Confirmation code.": "Código de confirmación.",
            "Login": "Iniciar sesión",
            "No Historic": "Sin historial",
            "Error in withdrawal": "Error en el retiro",
            "Back": "Volver",
            "Withdrawal amount": "Monto de retiro",
            "Enter the withdrawal amount": "Ingrese el monto de retiro",
            "Do you wish to continue?": "¿Desea continuar?",
            "Continue": "Continuar",
            "Destination Wallet": "Billetera de destino",
            "tax": "tarifa",
            "Attention": "Atención",
            "Transaction in progress, wait for confirmation in history.": "Transacción en curso, espere la confirmación en el historial.",
            "Insert valid phone": "Ingrese un teléfono válido",
            "Sell": "Vender",
            "Buy": "Comprar",
            "Documents": "Documentos",
            "Select the document type": "Seleccione el tipo de documento",
            'Personal_Documents': "Documentos personales",
            'Dependent_Documents': "Documentos dependientes",
            'Send date': "Fecha de envío",
            "Document Type": "Tipo de documento",
            'Upload documents': 'Cargar documentos',
            'Upload document': 'Cargar documento',
            'Search File': 'Buscar archivo',
            'Document sent': 'Documento enviado',
            'No records found': 'No se encontraron registros',
            'Select the file on your computer': 'Seleccione el archivo en su computadora',
            'Maximum file size': 'Tamaño máximo de archivo:',
            'Change Image': 'Cambiar imagen',
            'Data changed': 'Datos cambiados',
            'Enter phone number': 'Ingrese el número de teléfono',
            'To complete a transaction, you get your "Google Authenticator" activated.': 'Para completar una transacción, debe activar su "Google Authenticator".',
            'Do you want to continue?': '¿Desea continuar?',
            'Your Bitcoin wallet': 'Su billetera de Bitcoin',
            "Don't have a wallet": 'No tiene una billetera',
            "Generate Wallet": 'Generar billetera',
            "Successfully created wallet!": '¡Billetera creada con éxito!',
            "No records found.": 'No se encontraron registros.',
            "General Data": 'Datos generales',
            "Generate PDF": 'Generar PDF',
            "Sign": 'Firmar',
            "Personal data": 'Datos personales',
            "Zip Code": 'Código postal',
            "Street": 'Calle',
            "Number": 'Número',
            "Neighborhood": 'Barrio',
            "Complement": 'Complemento',
            "City": 'Ciudad',
            "State": 'Estado',
            "Application_Contratos": 'Contratos',
            "Application_Support": 'Soporte',
            "Application_AbrirChamado": 'Abrir llamado',
            "Application_Usuario": 'Usuario',
            "Application_Data": 'Fecha',
            "Application_Assinatura": 'Firma',
            "Application_Assunto": 'Asunto/Problema',
            "Application_Departamento": 'Departamento',
            "Application_Prioridade": 'Prioridad',
            "Application_Financeiro": 'Financiero',
            "Application_Baixa": 'Baja',
            "Send_Request_Support": 'Enviar solicitud de soporte',
            "New_Support_Ticket": 'Crear nuevo ticket de soporte',
            "Application_StartMarket": 'Configurar mercado',
            "Contract value": 'Valor del contrato',
            "Contract_N": 'Contrato N°',
            "Contract number": 'Número de contrato',
            "Incorrect Data": 'Datos incorrectos',
            "Mandatory Assignment": 'Asignación obligatoria',
            "Congratulations": 'Felicidades',
            "Start Date": 'Fecha de inicio',
            "Final Date": 'Fecha final',
            "Type of contract": 'Tipo de contrato',
            "Application_Seuscontratos": 'Sus contratos',
            "Country": 'País',
            "State_issuance": 'Estado de emisión',
            "Title_Page_Policy_And_Privacy": "Política de Privacidad y Cookies de Infinity Capital Global",
            "Last_Update": "Última actualización: ",
            "Thankyou_Choosing_Infinity_Capital_Global": "Agradecemos que haya elegido a Infinity Capital Global como su socio financiero. Valoramos su confianza y nos comprometemos a proteger su privacidad y garantizar la seguridad de su información personal. Esta Política de Privacidad y Cookies tiene como objetivo informar claramente cómo recopilamos, utilizamos, compartimos y protegemos sus datos personales.",
            "Consent": "Consentimiento",
            "By_Using_Our_Services": "Al utilizar nuestros servicios, usted acepta la recopilación y el uso de su información personal de acuerdo con esta Política de Privacidad y Cookies. Si no está de acuerdo con los términos de esta política, le recomendamos que no continúe utilizando nuestro sitio y servicios.",
            "About_The_Company": "Acerca de la empresa",
            "Infinity_Capital_Global_Is_A_Financial_Platform": "Infinity Capital Global es una plataforma financiera centrada en facilitar el acceso a los mercados de criptomonedas y acciones en todo el mundo. Nuestro objetivo es proporcionar una experiencia sólida y sencilla, ofreciendo servicios financieros, asesoramiento y herramientas para hacer la vida diaria de nuestros clientes más fácil.",
            "Data_Collect": "Recopilación de datos",
            "In_Order_To_Provide": "Para proporcionar servicios financieros personalizados y garantizar la seguridad de nuestros clientes, podemos recopilar la siguiente información personal: Información de identificación: Nombre completo, documento de identificación (CPF, RG u otro documento legalmente válido), fecha de nacimiento y nacionalidad. Información de contacto: Domicilio, dirección de correo electrónico, número de teléfono y otra información de contacto. Información financiera: Número de cuenta bancaria, información sobre transacciones e historial de pagos. Información sobre inversiones: Datos sobre inversiones, cartera y preferencias de inversión. Información de inicio de sesión: Nombre de usuario, contraseña y otra información necesaria para la autenticación en nuestra plataforma.",
            "Purposes_Data_Processing": "Finalidades del Tratamiento de Datos",
            "The_Personal_Data_Collected": "Los datos personales recopilados se utilizan para los siguientes fines: Proporcionar los servicios financieros solicitados por el cliente, incluida la ejecución de transacciones e inversiones. Cumplir con las obligaciones legales, reglamentarias y contractuales aplicables a los servicios financieros. Personalizar nuestra comunicación y proporcionar actualizaciones sobre nuevos productos, ofertas especiales, eventos e información relevante relacionada con los servicios que utiliza. Mejorar la calidad de nuestros servicios y garantizar la seguridad de nuestros clientes y de nuestra plataforma contra actividades fraudulentas o sospechosas.",
            "Data_Sharing": "Compartir Datos",
            "Infinity_Capital_Global_Will_Not_Share": "Infinity Capital Global no compartirá, venderá ni transferirá sus datos personales a terceros, excepto según se describe en esta política o con su consentimiento expreso para fines específicos. Podemos compartir sus datos personales con: Proveedores de servicios de terceros: Podemos utilizar empresas de terceros para brindar servicios en nuestro nombre, como procesamiento de pagos, análisis de datos, soporte al cliente, entre otros. Estas empresas tienen acceso limitado a su información y se les prohíbe utilizarla para cualquier otro propósito que no sea la prestación de los servicios contratados. Autoridades gubernamentales: En cumplimiento de obligaciones legales y reglamentarias, es posible que estemos obligados a compartir su información con autoridades gubernamentales u organismos reguladores.",
            "Data_Security": "Seguridad de Datos",
            "We_Adopt_Technical": "Adoptamos medidas técnicas, organizativas y de seguridad para proteger sus datos personales contra el acceso no autorizado, la pérdida, la alteración o la divulgación. Nuestros empleados están capacitados para tratar su información de manera confidencial y están sujetos a obligaciones de confidencialidad.",
            "Cookies_Tracking_Technologies": "Cookies y Tecnologías de Seguimiento",
            "Our_Website_Uses_Cookies": "Nuestro sitio utiliza cookies y tecnologías de seguimiento para mejorar su experiencia de navegación y recopilar información sobre el uso del sitio. Estas cookies se utilizan para recordar sus preferencias, analizar tendencias y patrones de navegación, personalizar el contenido y mostrar anuncios relevantes. Puede administrar sus preferencias de cookies en la configuración de su navegador. Al continuar utilizando nuestro sitio sin modificar la configuración de las cookies, acepta su uso.",
            "Your_Rights": "Sus Derechos",
            "You_Have_Rights": "Usted tiene derechos con respecto a sus datos personales: Acceso: Puede solicitar información sobre los datos que tenemos sobre usted. Rectificación: Si su información es incorrecta o está desactualizada, puede solicitar la corrección. Supresión: Tiene derecho a solicitar la supresión de sus datos, salvo que existan obligaciones legales o contractuales que impidan tal supresión. Portabilidad: Sus datos pueden transferirse a otra empresa si es técnicamente posible. Oposición: En ciertas circunstancias, puede oponerse al tratamiento de sus datos personales.",
            "Minors": "Menores de Edad",
            "Our_Services_Are_Not_Intended": "Nuestros servicios no están destinados a menores de 18 años. Si tenemos conocimiento de que hemos recopilado datos personales de un menor de edad, tomaremos medidas para eliminar esos datos lo antes posible.",
            "Application_O_Seriousness": "Aplicación de la Seriedad y el Cliente en Primer Lugar",
            "At_Infinity_Capital_Global": "En Infinity Capital Global, aplicamos seriedad en todo lo que hacemos. El cliente siempre está en primer lugar, y nuestro compromiso es proporcionar servicios financieros de alta calidad, soporte, asesoramiento y herramientas para facilitar su experiencia financiera.",
            "Changes_To_The_Privacy_Policy": "Cambios en la Política de Privacidad",
            "We_Reserve_The_Right_To_Update": "Nos reservamos el derecho de actualizar esta Política de Privacidad periódicamente para reflejar cambios en nuestras prácticas de privacidad. Le recomendamos que revise esta política regularmente para estar al tanto de las actualizaciones.",
            "Contact_And_Support": "Contacto y Soporte",
            "In_Case_Of_Douts": "Si tiene dudas, inseguridades o cualquier otra necesidad, nuestros clientes pueden ponerse en contacto con nosotros a través de los siguientes medios: Correo electrónico: contacto@infinitycapital.global Teléfono: 55 11 99999-0000 WhatsApp: [Número de WhatsApp]",
            "Monday to Friday from 09h to 18h": "De lunes a viernes de 09:00 a 18:00",
            "Useful links": "Enlaces útiles",
            "Privacy Policy": "Política de privacidad",
            "Terms of use": "Términos de uso",
            "Active": "Activo",
            "Historic": "Histórico",
            "Our story": "Nuestra historia",
            "How it works": "Cómo funciona",
            "Contact": "Contacto",
            "Description_Infinity_Capital": "Infinity Capital es la marca registrada de sitios web y plataformas digitales operados por INFINITY CAPITAL MANAGEMENT LTD. y sus empresas afiliadas pero independientes, Infinity Capital Securities LLC, Infinity Capital, Infinity Capital Digital Assets LLC, Infinity Capital Cash LLC. 43-45 Dorset Street, Londres, W1U 7NAW 1 U 7 NA - REINO UNIDO",
            "Application_EfetuarSaque": "Realizar retiro",
            "Total wallet": "Billetera total",
            "Withdrawal method": "Método de retiro",
            "Select a bank account": "Seleccione una cuenta bancaria",
            "Select a pix key": "Seleccione una clave PIX",
            "Your robots": "Sus robots",
            "application_textrodape": "Infinity Capital proporcionará al Cliente soporte comercialmente razonable e información pertinente para permitirle satisfacer los requisitos de todas las agencias reguladoras/de licencias aplicables y los estatutos asociados, incluida FSA Security (Seychelles), 8716294-1 SEC 108/13."

        }
    },
    interpolation: {
        escapeValue: true, // Permite interpretar as tags HTML como HTML real
    },
    react: {
        useSuspense: false,
    }
};
const cookies = new Cookies()
i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: cookies.get('lgs') == undefined ? 'pt' : cookies.get('lgs'),
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;