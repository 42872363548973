import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Header2 from "../pages/home/HeaderMenu";
import BottomBar from '../layout/sidebar/bottom-bar';
import { Loader } from "./home/components/loader";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector, useDispatch } from 'react-redux';
import { User } from "../store/User/User.action";
import { useTranslation } from "react-i18next";
import axios from "../../services";
import Moment from 'react-moment';

const StyledTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin: 15px 0;
    font-size: 0.9em;
    background-color: #1a1a1a;
    color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

    thead tr {
        background-color: #333;
        color: #ffffff;
        text-align: left;
    }

    th, td {
        padding: 12px 15px;
    }

    tbody tr {
        border-bottom: 1px solid #444;
    }

    tbody tr:nth-of-type(even) {
        background-color: #2a2a2a;
    }

    tbody tr:last-of-type {
        border-bottom: 2px solid #333;
    }

    tbody tr:hover {
        background-color: #555;
    }

    th, td {
        text-align: center;
    }

    .profit-positive {
        color: #19734A;
    }

    .profit-negative {
        color: #ff0000;
    }

    .status-active {
        background-color: #19734A; /* Green background */
        color: #000;              /* Black text */
        padding: 5px 10px;
        border-radius: 5px;
    }
    
    .status-inactive {
        background-color: #ff0000; /* Red background */
        color: #fff;               /* White text */
        padding: 5px 10px;
        border-radius: 5px;
    }

    .type-buy {
        color: #19734A;
    }

    .type-sell {
        color: #ff0000;
    }
`;

const Pagination = ({ currentPage, totalPages, onPageChange }) => (
    <div className="pagination">
        <button onClick={() => onPageChange(0)} disabled={currentPage === 0} className='btn btn-warning btn-sm p-1'>
            Primeiro
        </button>
        <button onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 0} className='btn btn-outline-primary btn-sm'>
            Anterior
        </button>
        
        <button onClick={() => onPageChange(currentPage + 1)} disabled={currentPage + 1 === totalPages} className='btn btn-outline-primary btn-sm'>
            Próximo
        </button>
        <button onClick={() => onPageChange(totalPages - 1)} disabled={currentPage + 1 === totalPages} className='btn btn-warning btn-sm p-1'>
            Último
        </button>

        {" "}<span style={{padding:10}}>Página {currentPage + 1} de {totalPages}</span>
    </div>
);

function Mercados() {
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [historic, setHistoric] = useState([]);
    const [Data, setData] = useState({});
    const [balance, setBalance] = useState({});
    const [releases, setReleases] = useState([]);
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isActive, setIsActive] = useState(true);
    const [mensagem, setMensagem] = useState('');
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [totalElements, setTotalElements] = useState(0);
    const [sortOrder, setSortOrder] = useState('desc');
    const [tipoMensagem, setTipoMensagem] = useState('');
    const [selectedSymbol, setSelectedSymbol] = useState('');
    const [filtersApplied, setFiltersApplied] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const fetchUserData = async () => {
        try {
            const response = await axios.post('/user/view/', { authKey: user.authKey });
            setData(response.data);
            setIsLoading(false);
        } catch (error) {
            console.error("Erro ao buscar os dados do usuário:", error);
        }
    };

    const fetchUserBalance = async () => {
        try {
            const response = await axios.post('/user/Balance/User/', { authKey: user.authKey });
            setBalance(response.data);
            setIsLoading(false);
        } catch (error) {
            console.error("Erro ao buscar os dados do usuário:", error);
        }
    };

    const fetchUserReleases = async () => {
        try {
            const response = await axios.post('/user/transactions/MT', { authKey: user.authKey });
            setReleases(response.data.releases);
            setIsLoading(false);
        } catch (error) {
            console.error("Erro ao buscar os dados do usuário:", error);
            setIsLoading(false);
        }
    };

    const fetchUserOrders = async () => {
        try {
            const response = await axios.post('/user/orders/MT', { authKey: user.authKey, active: isActive, page: page, size: size });
            let sortedOrders = response.data.orders.sort((a, b) => {
                if (sortOrder === 'desc') {
                    return new Date(b.time) - new Date(a.time);
                } else {
                    return new Date(a.time) - new Date(b.time);
                }
            });
            setOrders(sortedOrders);
            setTotalPages(response.data.page.totalPages);
            setTotalElements(response.data.page.totalElements);
        } catch (error) {
            console.error("Erro ao buscar os dados do usuário:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (user) {
            fetchUserData();
            fetchUserBalance();
            fetchUserReleases();
            fetchUserOrders();
        }
    }, [user, isActive, sortOrder, filtersApplied]); // Add sortOrder as a dependency

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         if (user) {
    //             fetchUserData();
    //             fetchUserBalance();
    //             fetchUserOrders();
    //         }
    //     }, 45000);

    //     return () => clearInterval(interval);
    // }, [user, isActive, sortOrder]);

    const handlePageChange = (newPage) => {
        if (newPage >= 0 && newPage < totalPages) {
            setPage(newPage);
            fetchUserOrders();
        }
    };

    const toggleSortOrder = () => {
        setSortOrder((prevOrder) => (prevOrder === 'desc' ? 'asc' : 'desc'));
    };

    const handleSymbolChange = (event) => {
        setSelectedSymbol(event.target.value);
    };

    const filterByDate = (order) => {
        const orderDate = new Date(order.time);
        const start = new Date(startDate);
        const end = new Date(endDate);

        if (startDate && orderDate < start) return false;
        if (endDate && orderDate > end) return false;
        return true;
    };

    const applyFilters = () => {
        setFiltersApplied(true);
    };

    const resetFilters = () => {
        setSelectedSymbol('');
        setIsActive(true);
        setPage(0);
        setSortOrder('desc');
        setStartDate('');
        setEndDate('');
        setFiltersApplied(true);
    };

    const CreateAccountMT = async () => {
        try {
            const response = await axios.post('/user/createUser/MT/', { authKey: user.authKey });
            setMensagem('Operação realizada com sucesso!');
            setTipoMensagem('success');
        } catch (error) {
            let mensagemErro = 'Ocorreu um erro desconhecido.';
            if (error.response) {
                mensagemErro = error.response.data.message || 'Erro na resposta do servidor.';
            } else if (error.request) {
                mensagemErro = 'Nenhuma resposta recebida do servidor.';
            } else {
                mensagemErro = error.message;
            }
            setMensagem(mensagemErro);
            setTipoMensagem('error');
        }
    };

    const uniqueSymbols = [...new Set(orders.map(order => order.ativoTicker))];
    const filteredOrders = orders
        .filter(order => (selectedSymbol ? order.ativoTicker === selectedSymbol : true))
        .filter(order => filterByDate(order));

    return (
        <>
            <Header2 title={t('Application_Mercados')} />
            {isLoading ? (
                <Loader />
            ) : (
                <div className="content-body">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-body" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div>
                                    <h3 className="card-title" style={{ marginBottom: 15 }}>Saldo Atual na plataforma FTM FX</h3>
                                    <h5>Saldo: ${parseFloat(balance.saldo || 0).toFixed(2)}</h5>
                                    <h5>Crédito: ${parseFloat(balance.credito || 0).toFixed(2)}</h5>
                                    <h5>Total Operating Profit: ${parseFloat(balance.totalOperatingProfit || 0).toFixed(2)}</h5>
                                    <h5>Total Margin: ${parseFloat(balance.totalMargin || 0).toFixed(2)}</h5>
                                </div>
                                <div>
                                    <img src="https://ftmfx.pro/assets/images/logotipo.png" alt="FTM FX Logo" style={{ maxWidth: '150px', height: 'auto' }} />
                                </div>
                            </div>
                        </div>

                        <div className="mt-4">
                            {Data.isMT === null || Data.isMT === 0 ? (
                                <button className="btn btn-outline-primary" onClick={CreateAccountMT}>
                                    Criar Conta na FTM FX PRO
                                </button>
                            ) : (
                                <a href="https://ftmfx.pro/" target="_blank" rel="noopener noreferrer" className="btn btn-outline-primary">
                                    Acessar Plataforma
                                </a>
                            )}
                        </div>

                        {mensagem && (
                            <div className={tipoMensagem === 'success' ? 'alert alert-success mt-2' : 'alert alert-danger mt-2'}>
                                {mensagem}
                            </div>
                        )}

                        <div className="card mt-4">
                            <div className="card-body">
                                <h5 className="card-title">Opções de Ordens</h5>
                                <div style={{ padding: 10 }}>
                                    <label>
                                        <input
                                            type="radio"
                                            name="orderType"
                                            value="abertas"
                                            checked={isActive}
                                            onChange={() => setIsActive(true)}
                                        />
                                        Ordens Abertas
                                    </label>
                                    <label style={{ marginLeft: '15px' }}>
                                        <input
                                            type="radio"
                                            name="orderType"
                                            value="fechadas"
                                            checked={!isActive}
                                            onChange={() => setIsActive(false)}
                                        />
                                        Ordens Fechadas
                                    </label>
                                    <button onClick={toggleSortOrder} className="btn btn-outline-secondary" style={{ marginLeft: '15px' }}>
                                        Sort by Date ({sortOrder === 'desc' ? 'Descending' : 'Ascending'})
                                    </button>

                                    <h5 className="card-title">Filtrar por Símbolo</h5>
                                    <div style={{ marginBottom: '10px' }}></div>
                                    <select onChange={handleSymbolChange} value={selectedSymbol} className='form-control'>
                                        <option value="">Todos</option>
                                        {uniqueSymbols.map((symbol, index) => (
                                            <option key={index} value={symbol}>{symbol}</option>
                                        ))}
                                    </select>
                                    <div style={{ marginBottom: '10px' }}></div>

                                    <h5 className="card-title">Filtrar por Data</h5>
                                    <div style={{ marginBottom: '10px' }}></div>
                                    <label>Data Inicial:</label>
                                    <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} className="form-control" />
                                    <div style={{ marginBottom: '10px' }}></div>
                                    <label>Data Final:</label>
                                    <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} className="form-control" />
                                    <div style={{ marginBottom: '10px' }}></div>

                                    <button className="btn btn-outline-success" onClick={applyFilters} style={{ marginRight: '15px' }}>
                                        Aplicar Filtro <i className='fa fa-check'></i>
                                    </button>

                                    <button className="btn btn-outline-danger" onClick={resetFilters}>
                                        Resetar Filtros <i className='fa fa-close'></i>
                                    </button>

                                </div>
                            </div>
                        </div>



                        <div className="card mt-4">
                            <div className="card-body">
                                <h5 className="card-title">Histórico de Ordens</h5>
                                <PerfectScrollbar>
                                    <div className="table-responsive">
                                        <StyledTable className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Hash</th>
                                                    <th>Time</th>
                                                    <th>Symbol</th>
                                                    <th>Type</th>
                                                    <th>Price</th>
                                                    <th>Vol</th>
                                                    <th>T/P</th>
                                                    <th>S/L</th>
                                                    <th>Profit</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredOrders.length > 0 ? (
                                                    filteredOrders.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{item.hash}</td>
                                                            <td><Moment format="DD/MM/YYYY - HH:mm">{item.time}</Moment></td>
                                                            <td><img src={`https://ftmfx.pro/assets/icons/${item.ativoTicker.replace(/\//g, '')}.png`} style={{ height: 15, marginRight: 5, marginTop: -5 }} alt={item.ativoTicker}></img>
                                                                {item.ativoTicker}
                                                            </td>
                                                            <td className={item.typeOperation === 'BUY' ? 'type-buy' : 'type-sell'}>
                                                                {item.typeOperation}
                                                            </td>
                                                            <td>${item.price}</td>
                                                            <td>{item.volume}</td>
                                                            <td>{item.takeProfit}</td>
                                                            <td>{item.stopLoss}</td>
                                                            <td className={item.operatingProfit >= 0 ? 'profit-positive' : 'profit-negative'}>
                                                                ${item.operatingProfit}
                                                            </td>
                                                            <td className={item.ativa ? 'status-active' : 'status-inactive'}>
                                                                {item.ativa ? 'Activated' : 'Deactivated'}
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="10" className="text-center">Sem histórico</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </StyledTable>
                                        <Pagination currentPage={page} totalPages={totalPages} onPageChange={handlePageChange} />
                                    </div>
                                </PerfectScrollbar>
                            </div>
                        </div>

                        <div className="card mt-4">
                            <div className="card-body">
                                <h5 className="card-title">Histórico de Transações</h5>
                                <PerfectScrollbar>
                                    <div className="table-responsive">
                                        <StyledTable className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Data</th>
                                                    <th>Tipo</th>
                                                    <th>Valor</th>
                                                    <th>Hash</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {releases.length > 0 ? (
                                                    releases.map((item, index) => (
                                                        <tr key={index}>
                                                            <td><Moment format="DD/MM/YYYY - HH:mm">{item.time}</Moment></td>
                                                            <td>{item.typeMovement}</td>
                                                            <td>${item.value.toFixed(2)}</td>
                                                            <td>{item.hash}</td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="4" className="text-center">Sem histórico</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </StyledTable>
                                    </div>
                                </PerfectScrollbar>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <BottomBar selectedIcon="markets" />
        </>
    );
}

export default Mercados;
