import Footer1 from "../layout/footer1";
import Header from "./home/components/header";

const TermsOfUse = () => {
  return(
    <>
     <Header />
     <p>Termos de uso</p>
     <Footer1 />
    </>
  );
};

export default TermsOfUse;