import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import axios from "../../../../services/index";
import { useSelector, useDispatch } from "react-redux";
import { User } from "../../../store/User/User.action";

// Componente para mostrar o modal de aceitação de termos
const ModalAceitacaoTermos = ({ onAccept, onReadTerms }) => {
    const { t } = useTranslation();

    return (
        <div style={modalStyle}>
            <div style={modalContentStyle}>
                <p>{t("Application_TermosAceite")}</p>
                <div style={buttonContainerStyle}>
                    <button onClick={onAccept} className='btn btn-success'>Aceitar Termos</button>
                    <button onClick={onReadTerms} className='btn btn-primary'>Ler Termos</button>
                </div>
            </div>
        </div>
    );
};

// Componente para mostrar o modal de leitura dos termos
const ModalLeituraTermos = ({ onClose, onConfirmTerms }) => {
    return (
        <div style={modalStyle}>
            <div style={{ ...modalContentStyle, overflowY: 'auto', maxHeight: '80%' }}>
                <h4 style={{ color: "#000000" }}>Termos e Condições</h4>
                <div style={modalStyle}>
                    <div style={{ ...modalContentStyle, overflowY: 'auto', maxHeight: '80%', textAlign: 'justify' }}>
                        <h4 style={{ color: "#000000" }}>Termos e Condições</h4>
                        <p>Bem-vindo aos Termos e Condições da Infinity Capital. Por favor, leia atentamente os seguintes termos antes de utilizar nosso site e serviços. Ao continuar navegando em nosso site e utilizar nossos serviços, você concorda em estar vinculado a estes Termos e Condições.</p>

                        <p>Aceitação dos Termos</p>
                        <p>Ao acessar ou utilizar nosso site e serviços, você concorda em cumprir e estar vinculado a estes Termos e Condições. Se você não concorda com qualquer parte destes termos, por favor, não utilize nosso site e serviços.</p>

                        <p>Uso do Site e Serviços</p>
                        <p>Você concorda em utilizar nosso site e serviços apenas para fins legítimos e de acordo com estes Termos e Condições. Você concorda em não utilizar nosso site e serviços para qualquer finalidade ilegal ou não autorizada.</p>

                        <p>Privacidade</p>
                        <p>Ao utilizar nosso site e serviços, você concorda com nossa Política de Privacidade, que detalha como coletamos, usamos e protegemos suas informações pessoais. É importante que você leia e compreenda nossa Política de Privacidade.</p>

                        <p>Propriedade Intelectual</p>
                        <p>Todo o conteúdo presente em nosso site, incluindo textos, imagens, logotipos e gráficos, é de propriedade da Infinity Capital e está protegido por leis de direitos autorais. Você concorda em não reproduzir, distribuir ou utilizar qualquer conteúdo de nosso site sem autorização prévia por escrito.</p>

                        <p>Responsabilidade do Usuário</p>
                        <p>Você é o único responsável por qualquer conteúdo que enviar ou disponibilizar em nosso site. Você concorda em não enviar qualquer conteúdo que seja difamatório, obsceno, ilegal ou que viole os direitos de terceiros.</p>

                        <p>Aviso de Risco</p>
                        <p>Negociação de produtos derivativos alavancados, como Câmbio (Forex), Contratos de Diferença (CFDs) ou outros produtos financeiros derivados acarretam um alto nível de risco para o seu capital. Todos estes produtos, que são produtos derivados alavancados, podem não ser apropriados para todos os investidores. O efeito da alavancagem é que tanto os ganhos como as perdas são ampliados. Os preços dos derivativos alavancados produtos podem mudar em sua desvantagem muito rapidamente, você pode perder mais do que seu capital investido, e você pode ser obrigado a fazer pagamentos adicionais. Antes de decidir investir em qualquer produto financeiro, você deve considerar cuidadosamente seus objetivos de investimento, conhecimento e experiência comercial e acessibilidade. Você só deve negociar Forex e CFDs se tiver conhecimento e experiência suficientes da natureza arriscada dos produtos, dos riscos envolvidos na negociação de tais produtos e se você estiver negociando com dinheiro que você pode perder. Você deve procurar aconselhamento financeiro profissional independente se você está com alguma dúvida.</p>

                        <h4>Negocie por Sua Própria Conta e Risco</h4>
                        <p>A Infinity Capital não tem obrigação:</p>
                        a) de garantir-nos quanto à adequação de qualquer Posição para você;
                        b) de monitorar ou aconselhá-lo sobre o status de qualquer uma de suas Posições;
                        c) impedir que você negocie além de seus meios ou capacidade ou protegê-lo; ou
                        d) fechar qualquer Posição aberta.

                        <p>Limitação de Responsabilidade</p>
                        <p>Não nos responsabilizamos por quaisquer danos ou perdas decorrentes do uso ou incapacidade de uso de nosso site e serviços. Isso inclui, mas não se limita a, danos diretos, indiretos, incidentais, especiais, consequenciais ou punitivos.</p>

                        <p>Alterações nos Termos</p>
                        <p>Reservamos o direito de modificar estes Termos e Condições a qualquer momento, sem aviso prévio. É sua responsabilidade revisar regularmente estes termos para estar ciente de quaisquer alterações.</p>

                        <p>Leis e Jurisdição Aplicáveis</p>
                        <p>Estes Termos e Condições serão regidos e interpretados de acordo com as leis do Brasil. Qualquer disputa relacionada a estes termos será submetida à jurisdição exclusiva dos tribunais localizados no Brasil.</p>

                        <p>Prazo de Investimento e Política de Retirada Antecipada</p>

                        <p>Informamos que todo capital aplicado, incluindo o primeiro aporte e posteriores, estará sujeito a um prazo de investimento de 12 meses para operações. Essa medida visa assegurar a estabilidade e segurança das operações realizadas em nossa plataforma. Ao término deste período, o cliente terá a liberdade de renovar o prazo de investimento de acordo com sua preferência.</p>

                        <p>É importante destacar que a solicitação de retirada antes do término do prazo estipulado implicará em uma multa por quebra de contrato. A multa será equivalente a 70% do valor total do capital investido, independentemente de sua utilização na plataforma.</p>

                        <p>Conformidade Legal: Legislação Brasileira e Diretrizes do Governo do Reino Unido</p>

                        <p>Em conformidade com nossa política de transparência e responsabilidade, é fundamental destacar que cumprimos rigorosamente os termos da legislação brasileira, garantindo total conformidade com as normas e regulamentações estabelecidas pelas autoridades competentes. Além disso, reiteramos nosso compromisso em seguir as diretrizes estabelecidas pelo governo do Reino Unido, adotando práticas alinhadas com os padrões internacionais de governança e conformidade. Esses princípios fundamentais orientam todas as nossas operações, assegurando a confiança e a segurança de nossos clientes em todas as interações com nossa empresa.</p>


                        <p>Ao marcar a caixa abaixo, você confirma que leu, compreendeu e concorda em estar vinculado a estes Termos e Condições.</p>
                        <div style={checkboxContainerStyle}>
                            <label>
                                <input type="checkbox" onChange={onConfirmTerms} />
                                Eu concordo com os Termos e Condições.
                            </label>
                        </div>
                        <button onClick={onClose} className='btn btn-secondary'>Fechar</button>
                    </div>
                </div>
                <div style={checkboxContainerStyle}>
                    <label>
                        <input type="checkbox" onChange={onConfirmTerms} />
                        Eu concordo com os Termos e Condições.
                    </label>
                </div>
                <button onClick={onClose} className='btn btn-secondary'>Fechar</button>
            </div>
        </div>
    );
};

// Componente principal que controla a visibilidade dos modais
const TermosAceitacao = ({ isAcceptTerms }) => {
    const user = useSelector((state) => state.user);
    const [acceptTerms, setAcceptTerms] = useState(isAcceptTerms);
    const [isReadingTerms, setIsReadingTerms] = useState(false);

    useEffect(() => {
        setAcceptTerms(isAcceptTerms);
    }, [isAcceptTerms]);

    const handleAcceptTerms = async () => {
        try {
            await axios.post("user/updateTerms", { authKey: user.authKey, isAcceptTerms: 1 });
            setAcceptTerms(1);
            setIsReadingTerms(false);  // Fechar todos os modais ao aceitar
        } catch (err) {
            console.log("erro");
        }


    };

    const handleReadTerms = () => {
        setIsReadingTerms(true);
    };

    const handleCloseReadTerms = () => {
        setIsReadingTerms(false);
    };

    const handleConfirmTerms = (e) => {
        if (e.target.checked) {
            handleAcceptTerms()
            setAcceptTerms(1);
            setIsReadingTerms(false);  // Fechar o modal de leitura ao confirmar
        }
    };

    return (
        <>
            {(acceptTerms === null || acceptTerms === 0) && (
                <ModalAceitacaoTermos onAccept={handleAcceptTerms} onReadTerms={handleReadTerms} />
            )}
            {isReadingTerms && (
                <ModalLeituraTermos onClose={handleCloseReadTerms} onConfirmTerms={handleConfirmTerms} />
            )}
        </>
    );
};

TermosAceitacao.propTypes = {
    isAcceptTerms: PropTypes.number
};

export default TermosAceitacao;

const modalStyle = {
    position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 9999,

};

const modalContentStyle = {
    backgroundColor: 'white', padding: '20px', borderRadius: '10px', width: '90%', maxWidth: '800px',
};

const buttonContainerStyle = {
    display: 'flex', justifyContent: 'space-around', marginTop: '20px'
};

const checkboxContainerStyle = {
    marginTop: '20px',
    height: "35px",
    with: '35px'

};

