import Footer1 from "../layout/footer1";
import Header from "./home/HeaderMenuIndex.jsx";

const Actives = () => {
  return(
    <>
     <Header />
     <p>Ativos</p>
     <Footer1 />
    </>
  );
};

export default Actives;